import { FileType, GenericListDocuments, ProposalModel } from '_types'

import * as ProposalTypes from './types'

export function changeProposal(proposal: ProposalModel): ProposalTypes.Actions {
  return {
    type: ProposalTypes.Types.UPDATE_PROPOSAL,
    payload: { proposal }
  }
}

export function updatePersonalDocuments(
  idProponent: string,
  documentKey: keyof GenericListDocuments,
  files: FileType[]
): ProposalTypes.Actions {
  return {
    type: ProposalTypes.Types.UPDATE_PERSONAL_DOCUMENTS,
    payload: {
      idProponent,
      documentKey,
      files
    }
  }
}

export function updateSpouseDocuments(
  idProponent: string,
  documentKey: keyof GenericListDocuments,
  files: FileType[]
): ProposalTypes.Actions {
  return {
    type: ProposalTypes.Types.UPDATE_SPOUSE_DOCUMENTS,
    payload: {
      idProponent,
      documentKey,
      files
    }
  }
}
export function updateComplementaryDocuments(
  idProponent: string,
  files: FileType[]
): ProposalTypes.Actions {
  return {
    type: ProposalTypes.Types.UPDATE_COMPLEMENTARY_DOCUMENTS,
    payload: {
      idProponent,
      files
    }
  }
}
