import React from 'react'

import styles from './Button.module.scss'

export type ButtonProps = {
  label: string
  isDisable?: boolean
  onClick?: () => void
  typeButton?: 'primary' | 'outline' | 'underlined'
  className?: string
  icon?: JSX.Element
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>

export const Button: React.FC<ButtonProps> = ({
  label,
  isDisable = false,
  typeButton = 'primary',
  className = '',
  icon,
  ...props
}) => {
  return (
    <button
      type='button'
      className={`${isDisable === true ? styles.disabled : ''} ${
        styles[typeButton]
      } ${className} `}
      disabled={isDisable}
      {...props}
    >
      <div className={styles.label}>
        {label}
        {icon}
      </div>
    </button>
  )
}
