import React from 'react'

import { RadioButton } from 'components'

import styles from './cardProponent.module.scss'

export type CardProponentProps = {
  element: {
    key: string
    active: boolean
    name: string
    status: string
  }
  onClick: (key: string | boolean) => void
}

export const CardProponent: React.FC<CardProponentProps> = ({
  element,
  onClick
}) => {
  const { key, active, name, status } = element

  return (
    <div className={styles.container}>
      <RadioButton
        key={key}
        checked={active}
        value={key}
        name={name}
        onChange={onClick}
        className={styles.icon}
      />
      <div className={styles.texts}>
        <h1>{name}</h1>
        <p>{status}</p>
      </div>
    </div>
  )
}
