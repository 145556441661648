import { useState, useEffect, useCallback } from 'react'

import { toast } from 'react-toastify'

import { ProponentReducedModel } from '_types'
import { listProponents } from 'services/api'

export type UseSavedProponents = {
  proponents: ProponentReducedModel[]
  loading: boolean
  setLoading: (state: boolean) => void
  loadData: () => void
}

export function useSavedProponents(): UseSavedProponents {
  const [proponents, setProponents] = useState<ProponentReducedModel[]>([])
  const [loading, setLoading] = useState(false)

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const response = await listProponents()
      setProponents(response)
    } catch (error) {
      toast.error('Erro na listagem dos proponentes')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  return {
    proponents,
    loading,
    setLoading,
    loadData
  }
}
