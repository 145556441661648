import React from 'react'

import styles from './svg.module.scss'

type LinkedinTypes = {
  viewBox?: string
  color?:
    | 'primaryColor'
    | 'darkColor'
    | 'whiteColor'
    | 'greenColor'
    | 'greyColor'
    | 'blackLight'
}

const Linkedin: React.FC<LinkedinTypes> = ({
  viewBox = '0 0 32 32',
  color = 'primaryColor'
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='19px'
      height='100%'
      viewBox={viewBox}
    >
      <defs>
        <path
          id='suite'
          d='M9.593 9.134L9.06 4.348a.3.3 0 0 1 .432-.301l3.283 1.641a.3.3 0 0 0 .374-.088l2.61-3.48a.3.3 0 0 1 .48 0l2.61 3.48a.3.3 0 0 0 .374.088l3.283-1.641a.3.3 0 0 1 .432.301l-.532 4.786c1.138.496 2.23 1.13 3.255 1.902V8.988c0-.617.49-1.117 1.093-1.117.604 0 1.094.5 1.094 1.117v9.86c1.229.272 2.152 1.39 2.152 2.725v4.913c0 .617-.49 1.117-1.094 1.117h-2.133v1.08c0 .616-.49 1.116-1.093 1.116-.604 0-1.094-.5-1.094-1.117v-1.079H7.414v1.08c0 .616-.49 1.116-1.094 1.116-.604 0-1.093-.5-1.093-1.117v-1.079H3.094c-.604 0-1.094-.5-1.094-1.117v-4.913c0-1.335.923-2.453 2.151-2.727V8.92c0-.617.49-1.117 1.094-1.117.604 0 1.093.5 1.093 1.117v2.117a16.058 16.058 0 0 1 3.255-1.902zm12.555 2.334l-.118 1.065a.3.3 0 0 1-.299.267H10.27a.3.3 0 0 1-.299-.267l-.118-1.062a14.063 14.063 0 0 0-3.514 2.47v4.841h4.266v-3.275c0-.617.49-1.116 1.094-1.116h8.604c.604 0 1.094.5 1.094 1.116v3.275h4.266v-4.841a14.089 14.089 0 0 0-3.514-2.473zm-9.356 7.315h6.416v-2.16h-6.416v2.16zm15.02 6.588v-3.797a.553.553 0 0 0-.546-.558H4.734a.553.553 0 0 0-.546.558v3.797h23.625zM12.06 10.8h7.882a.3.3 0 0 0 .299-.267l.305-2.752a.3.3 0 0 0-.432-.301l-1.488.743a.3.3 0 0 1-.374-.088L16.24 5.453a.3.3 0 0 0-.48 0l-2.011 2.682a.3.3 0 0 1-.374.088l-1.488-.743a.3.3 0 0 0-.432.301l.305 2.752a.3.3 0 0 0 .299.267z'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='b'>
          <use xlinkHref='#suite' />
        </mask>
        <use className={styles[color]} fillRule='nonzero' xlinkHref='#suite' />
        <g mask='url(#b)'>
          <path d='M0 0h32v32H0z' />
        </g>
      </g>
    </svg>
  )
}

export default Linkedin
