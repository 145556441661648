import React from 'react'

import styles from './PurchaseInformatiom.module.scss'

type purchaseInformationType = {
  numberProposal?: string
  idProperty?: string | number
  position?: number
}

export const PurchaseInformation: React.FC<purchaseInformationType> = ({
  numberProposal,
  idProperty,
  position
}) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Informações da compra</h2>
      <p>Nº proposta: {numberProposal}</p>
      <p>ID do imóvel: {idProperty}</p>
      <p>Posição na fila: {position}</p>
    </div>
  )
}
