import React, { useState } from 'react'

import { ProponentModel } from '_types'
import { Modal, RegisterProponent, CardProponent, Button } from 'components'

import styles from './ModalAddProponents.module.scss'

export type Element = {
  key: string | boolean
  active: boolean
  name: string
  status: string
}
export type ModalAddProponentsProps = {
  open: boolean
  proponents?: ProponentModel[]
  onClick: () => void
  closeModal: () => void
  onClickContinues: (proponet: ProponentModel | undefined) => void
}

export const ModalAddProponents: React.FC<ModalAddProponentsProps> = ({
  open,
  proponents = [],
  closeModal,
  onClick,
  onClickContinues
}) => {
  const [selectedProponent, setSelectedProponent] = useState('')

  const handleClick = (key: string | boolean) => {
    setSelectedProponent(key as string)
  }

  const clickContinues = () => {
    const findedProponent = proponents.find(p => p.id === selectedProponent)
    if (!findedProponent) return
    onClickContinues(findedProponent)
  }

  return (
    <Modal isOpen={open} closeModal={closeModal} className={styles.container}>
      <div className={`${styles.container}`}>
        <h1 className={styles.title}>Adicionar proponente</h1>
        <RegisterProponent onClick={onClick} />

        <div className={styles.cards}>
          {proponents.map((proponent, key) => (
            <CardProponent
              key={key}
              element={{
                key: proponent.id,
                active: selectedProponent === proponent.id,
                name: proponent.name,
                status: proponent.situation
              }}
              onClick={handleClick}
            />
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        <Button label='Cancelar' typeButton='outline' onClick={closeModal} />
        <Button
          label='Continuar'
          onClick={clickContinues}
          isDisable={!selectedProponent}
        />
      </div>
    </Modal>
  )
}
