import estateBb from './estateBb.png'
import estateBbMobile from './estateBbMobile.png'
import estateCaixa from './estateCaixa.png'
import estateCaixaMobile from './estateCaixaMobile.png'
import estateEmbracon from './estateEmbracon.jpg'
import estateEmbraconMobile from './estateEmbraconMobile.jpg'
import estateEmgea from './estateEmgea.jpg'
import estateEmgeaMobile from './estateEmgeaMobile.jpg'
import estateImoveisDesconto from './estateImoveisDesconto.png'
import estateImoveisDescontoMobile from './estateImoveisDescontoMobile.png'
import listBb from './listBb.png'
import listBbMobile from './listBbMobile.png'
import listCaixa from './listCaixa.png'
import listCaixaMobile from './listCaixaMobile.png'
import listEmbracon from './listEmbracon.jpg'
import listEmbraconMobile from './listEmbraconMobile.jpg'
import listEmgea from './listEmgea.png'
import listEmgeaMobile from './listEmgeaMobile.png'
import listImoveisDesconto from './listImoveisDesconto.png'
import listImoveisDescontoMobile from './listImoveisDescontoMobile.png'

export const listBanners = {
  '52190fbd-99b6-3d3e-494d-5d8140d5a0e0': {
    desktop: listBb,
    mobile: listBbMobile
  },
  '4ed450aa-7600-d4ef-95ed-5f4ea43a9dee': {
    desktop: listEmgea,
    mobile: listEmgeaMobile
  },
  '94cd5537-8446-9352-94a7-5d1b6d8204e0': {
    desktop: listEmbracon,
    mobile: listEmbraconMobile
  },
  'f0a80116-cc08-5b24-3b84-5f6279c5311e': {
    desktop: listImoveisDesconto,
    mobile: listImoveisDescontoMobile
  },
  '411d41d1-deec-2a2f-e83f-5f4ea405d045': {
    desktop: listCaixa,
    mobile: listCaixaMobile
  }
}

export const estateBanners = {
  '52190fbd-99b6-3d3e-494d-5d8140d5a0e0': {
    desktop: estateBb,
    mobile: estateBbMobile
  },
  '4ed450aa-7600-d4ef-95ed-5f4ea43a9dee': {
    desktop: estateEmgea,
    mobile: estateEmgeaMobile
  },
  'f0a80116-cc08-5b24-3b84-5f6279c5311e': {
    desktop: estateImoveisDesconto,
    mobile: estateImoveisDescontoMobile
  },
  '94cd5537-8446-9352-94a7-5d1b6d8204e0': {
    desktop: estateEmbracon,
    mobile: estateEmbraconMobile
  },
  '411d41d1-deec-2a2f-e83f-5f4ea405d045': {
    desktop: estateCaixa,
    mobile: estateCaixaMobile
  }
}
