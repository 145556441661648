import React from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

import {
  SavedProponentsPage,
  ProfilePage,
  ProposalPage,
  FaqPage,
  ProposalListPage,
  ProposalDetailsPage,
  FilesUploadPage
} from 'pages'

import { Routes } from './routes'

export function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={Routes.FILES_TO_UPLOAD()} exact>
          <FilesUploadPage />
        </Route>
        <Route path={Routes.PROPOSAL(':id')}>
          <ProposalPage />
        </Route>
        <Route path={Routes.PROPONENTS()}>
          <SavedProponentsPage />
        </Route>
        <Route path={Routes.PROFILE()}>
          <ProfilePage />
        </Route>
        <Route path={Routes.FAQ()}>
          <FaqPage />
        </Route>
        <Route path={Routes.PROPOSALS()}>
          <ProposalListPage />
        </Route>

        <Route path={Routes.PROPOSALS(':id')}>
          <ProposalDetailsPage />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
