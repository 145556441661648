import React, { useEffect, useMemo, useState } from 'react'

import { Input } from 'components'
import { OutsideAlerter } from 'hooks/useOutsideAlerter'
import { sizeStyle } from 'utils/sizeStyle'

import styles from './SelectInput.module.scss'

type Option = {
  name: string
  value: string
}

export type SelectProps = {
  onChange: (value: string) => void
  selected: string
  onFocus?: () => void
  options: Option[]
  label?: string
  placeholder?: string
  required?: boolean
  size?: sizeStyle
  className?: string
}

export const SelectInput: React.FC<SelectProps> = ({
  onChange,
  selected,
  onFocus,
  options,
  size = 'small',
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [line, setLine] = useState(0)

  const [touched, setTouched] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', changeRow)
    return () => {
      document.removeEventListener('keydown', changeRow)
    }
  })

  const setValue = ({ value }: { value: string }) => {
    onChange(value)
    setLine(0)
    setIsFocused(false)
  }

  // <initial> navigation of options by keyboard arrow
  const getRowIncrement = (e: KeyboardEvent) => {
    if (e.keyCode === 38) return -1
    if (e.keyCode === 40) return 1
    return 0
  }

  const changeRow = (e: KeyboardEvent) => {
    const rowIncrement = getRowIncrement(e)
    if (!rowIncrement) return

    e.stopPropagation()
    e.preventDefault()
    const nextLine = (line + rowIncrement + options.length) % options.length

    const nextElement = document.getElementById(
      `locality${nextLine - 1 === -1 ? options.length - 1 : nextLine - 1}`
    )
    nextElement && nextElement.focus()
    setLine(nextLine)
  }
  // <end> navigation of options by keyboard arrow

  const inputValue = useMemo(
    () => options.find(option => option.value === selected)?.name || '',
    [selected, options]
  )

  return (
    <OutsideAlerter
      action={() => {
        setIsFocused(false)
        setLine(0)
      }}
      className={`${styles.alerter} ${styles[size]} `}
    >
      <Input
        readOnly
        onChange={(_, e) => onChange(e.target.value)}
        value={inputValue}
        type='text'
        onFocus={() => {
          setIsFocused(true)
          setTouched(true)
          onFocus && onFocus()
        }}
        select={true}
        validateOnBlur={false}
        touched={touched}
        {...rest}
      />
      <ul
        role='listbox'
        className={`${styles.options} ${isFocused ? styles.open : ''}`}
      >
        {options.map(({ name, value }, index) => (
          <li
            role='option'
            id={`locality${index}`}
            aria-selected={
              selected === value || line === index ? 'true' : 'false'
            }
            tabIndex={index}
            className={`${styles.option}`}
            key={index.toString()}
            onClick={() => setValue({ value })}
            onKeyPress={() => {
              setValue({ value })
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                setValue({ value })
                setLine(0)
              }
            }}
          >
            {name}
          </li>
        ))}
      </ul>
    </OutsideAlerter>
  )
}
