import React, { useState } from 'react'

import { SelectInput } from 'components'

import styles from './HeaderDateFilter.module.scss'

export type HeaderDateFilterProps = {
  totalItems: number
  options?: { name: string; value: string }[]
}

const defaultOptions = [
  {
    name: '3 meses',
    value: '3m'
  },
  {
    name: '6 meses',
    value: '6m'
  },
  {
    name: '9 meses',
    value: '9m'
  },
  {
    name: '12 meses',
    value: '12m'
  }
]

export const HeaderDateFilter: React.FC<HeaderDateFilterProps> = ({
  totalItems = 0,
  options = defaultOptions
}) => {
  const [value, setValue] = useState('3m')

  return (
    <div className={styles.container}>
      <span>{totalItems} propostas encontradas</span>
      <div className={styles.selectContainer}>
        <p>Exibir os últimos</p>
        <SelectInput options={options} selected={value} onChange={setValue} />
      </div>
    </div>
  )
}
