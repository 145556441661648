export const helpQuestions = {
  1: [
    { label: 'Posso adicionar proponentes posteriormente?', url: '' },
    { label: 'Meu cônjuge conta como outro proponente?', url: '' },
    {
      label: 'Posso adicionar uma empresa como proponente adicional?',
      url: ''
    }
  ],
  2: [
    { label: 'Existe valor mínimo para oferta?', url: '' },
    { label: 'Para quem é pago o valor da comissão?', url: '' },
    {
      label: 'Posso parcelar o valor dos honorários?',
      url: ''
    },
    {
      label: 'Posso declarar recursos de terceiros na minha proposta?',
      url: ''
    },
    {
      label:
        'Como serão efetuados os pagamentos da entrega e do restante das parvelas?',
      url: ''
    }
  ],
  3: [
    { label: 'Como altero minha proposta?', url: '' },
    { label: 'Posso editar os proponentes?', url: '' },
    { label: 'A proposta tem valor de contrato?', url: '' },
    { label: 'Como funciona a assinatura digital?', url: '' }
  ],
  4: [
    { label: 'Como verifico os documentos que preciso enviar?', url: '' },
    { label: 'Posso enviar a documentação de apenas um proponente?', url: '' },
    { label: 'Os documentos precisam ser validados?', url: '' }
  ]
}
