export default {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    partialVisibilityGutter: 0,
    items: 1
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: 1,
    partialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464
    },
    items: 1,
    partialVisibilityGutter: 0
  }
}
