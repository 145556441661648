import React from 'react'

import { FileType, GenericListDocuments } from '_types'
import { DocumentCard } from 'components'
import useStore from 'store'
import { updatePersonalDocuments } from 'store/proposal/actions'

import styles from './index.module.scss'

type PersonalStepProps = {
  personalDocuments: Partial<GenericListDocuments>
  idProponent: string
}
export const PersonalStep: React.FC<PersonalStepProps> = ({
  personalDocuments,
  idProponent
}) => {
  const { dispatch } = useStore()

  const handleChangeFiles = (
    documentKey: keyof GenericListDocuments,
    files: FileType[]
  ) => {
    dispatch(updatePersonalDocuments(idProponent, documentKey, files))
  }
  return (
    <div className={styles.container}>
      {Object.entries(personalDocuments).map(
        ([documentKey, document]) =>
          document && (
            <DocumentCard
              title={document.title}
              status={document.status}
              files={document.files}
              onChangeFiles={files =>
                handleChangeFiles(
                  documentKey as keyof GenericListDocuments,
                  files
                )
              }
              key={documentKey}
            />
          )
      )}
    </div>
  )
}
