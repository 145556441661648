/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useState } from 'react'

import { freeze } from 'utils/freeze/freeze'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateDocs = async (files: File[]) => {
  await freeze()

  return {
    files: files,
    status: true
  }
}

export type useLoginProps = {
  isWelcomeModalOpen: boolean
  isDocValidationDisapproved: boolean
  isValidatingDocModal: boolean
  isDocValidationSuccess: boolean
  errorMessageFront: string | undefined
  errorMessageBack: string | undefined
  onValidateDocs: (files: File[]) => void
  setErrorMessageBack: Dispatch<SetStateAction<string | undefined>>
  setErrorMessageFront: Dispatch<SetStateAction<string | undefined>>
  setIsWelcomeModalOpen: Dispatch<SetStateAction<boolean>>
  setIsValidatingDocModal: Dispatch<SetStateAction<boolean>>
  setIsDocValidationSuccess: Dispatch<SetStateAction<boolean>>
  setIsDocValidationDisapproved: Dispatch<SetStateAction<boolean>>
}

export const useFiles = (): useLoginProps => {
  const [errorMessageFront, setErrorMessageFront] = useState<
    string | undefined
  >(undefined)
  const [errorMessageBack, setErrorMessageBack] = useState<string | undefined>(
    undefined
  )
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true)
  const [isValidatingDocModal, setIsValidatingDocModal] = useState(false)
  const [isDocValidationSuccess, setIsDocValidationSuccess] = useState(false)
  const [isDocValidationDisapproved, setIsDocValidationDisapproved] = useState(
    false
  )

  const onValidateDocs = async (files: File[]) => {
    setIsValidatingDocModal(true)
    const response = await validateDocs(files)

    setIsValidatingDocModal(false)

    if (response.status) {
      setIsDocValidationSuccess(true)
    } else {
      setIsDocValidationDisapproved(true)
      setErrorMessageFront('O CPF está ilegivel')
      setErrorMessageBack(undefined)
    }
  }

  return {
    isWelcomeModalOpen,
    isValidatingDocModal,
    isDocValidationSuccess,
    isDocValidationDisapproved,
    errorMessageFront,
    errorMessageBack,
    onValidateDocs,
    setErrorMessageBack,
    setErrorMessageFront,
    setIsWelcomeModalOpen,
    setIsValidatingDocModal,
    setIsDocValidationSuccess,
    setIsDocValidationDisapproved
  }
}
