import React from 'react'

import classnames from 'classnames'

import { Modal, Button } from 'components'

import styles from './RequiredDocsModal.module.scss'

type DocsProps = { doc: string; status: string }[]

type StatusProps = { [key: string]: string }

export type RequiredDocsModalProps = {
  proponentName: string
  proponentDocs: DocsProps
  spouseDocs: DocsProps
  complementaryDocs: DocsProps
  isOpen: boolean
  closeModal: () => void
}

export const RequiredDocsModal: React.FC<RequiredDocsModalProps> = ({
  proponentName,
  proponentDocs,
  spouseDocs,
  complementaryDocs,
  isOpen,
  closeModal
}) => {
  const status: StatusProps = {
    validacao_manual: 'Documento em validação manual',
    validacao_automatica: 'Documentos em validação automática'
  }

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} className={styles.container}>
      <h1>Documentos requisitados</h1>
      <p>{proponentName}</p>

      <section className={styles.containerGrid}>
        <article>
          <h2>Documentos do proponente</h2>
          {!!proponentDocs.length && (
            <div>
              <div className={styles.content}>
                <ul className={styles.listDocs}>
                  {proponentDocs.map(item => (
                    <div key={item.doc} className={styles.doc}>
                      <div
                        className={classnames({
                          [styles.ball]: !item.status,
                          [styles.ballYellow]:
                            item.status === 'validacao_automatica',
                          [styles.ballOrange]:
                            item.status === 'validacao_manual'
                        })}
                      />
                      <li>
                        <p>{item.doc}</p>
                        {!!item.status && <span>{status[item.status]}</span>}
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </article>
        <article>
          <h2>Documentos do cônjuge</h2>
          {!!spouseDocs.length && (
            <div>
              <div className={styles.content}>
                <ul className={styles.listDocs}>
                  {spouseDocs.map(item => (
                    <div key={item.doc} className={styles.doc}>
                      <div
                        className={classnames({
                          [styles.ball]: !item.status,
                          [styles.ballYellow]:
                            item.status === 'validacao_automatica',
                          [styles.ballOrange]:
                            item.status === 'validacao_manual'
                        })}
                      />
                      <li>
                        <p>{item.doc}</p>
                        {!!item.status && <span>{status[item.status]}</span>}
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </article>
        <article className={styles.complementaryDocs}>
          <h2>Documentos complementares</h2>
          {!!complementaryDocs.length && (
            <div>
              <div className={styles.contentBottom}>
                <ul className={styles.listDocs}>
                  {complementaryDocs.map(item => (
                    <div key={item.doc} className={styles.doc}>
                      <div
                        className={classnames({
                          [styles.ball]: !item.status,
                          [styles.ballYellow]:
                            item.status === 'validacao_automatica',
                          [styles.ballOrange]:
                            item.status === 'validacao_manual'
                        })}
                      />
                      <li>
                        <p>{item.doc}</p>
                        {!!item.status && <span>{status[item.status]}</span>}
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </article>
      </section>

      <h6>*Documentos obrigatórios</h6>
      <div className={styles.btnContainer}>
        <Button label='Anexar documentos' />
      </div>
    </Modal>
  )
}
