import React from 'react'

import Switch from 'react-switch'

import styles from './Toggle.module.scss'

export type ToggleProps = {
  option?: string
  checked: boolean
  onChange: () => void
  id: string
  optionStrong?: string
  disabled?: boolean
}

export const Toggle: React.FC<ToggleProps> = ({
  option = '',
  optionStrong = '',
  checked,
  onChange,
  id,
  disabled = false
}) => (
  <label htmlFor={id} className={styles.container}>
    <Switch
      id={id}
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor='#00BFB2'
      height={24}
      width={41}
    />
    {!!option && (
      <p>
        {option} {!!optionStrong && <span>{optionStrong}</span>}
      </p>
    )}
  </label>
)
