import React from 'react'

import { CurrencyInput, SelectInput } from 'components'
import { useProposal } from 'store/hooks'
import { installmentsOptions } from 'utils/selectOptions'

import styles from './HowWillBePaidSection.module.scss'

type HowWillBePaidSectionProps = {
  handleChangePaymentDetails: (key: string) => (value: string) => void
}

export const HowWillBePaidSection: React.FC<HowWillBePaidSectionProps> = ({
  handleChangePaymentDetails
}) => {
  const { proposal } = useProposal()

  return (
    <section className={styles.howPropertyWillBePaidSection}>
      <h2>Como será pago o imóvel?</h2>
      <div>
        <CurrencyInput
          label='Entrada'
          value={proposal.paymentDetails.input}
          onChange={handleChangePaymentDetails('input')}
          size='max'
        />
        <span>{'+'}</span>

        <CurrencyInput
          label='Saldo'
          value={proposal.paymentDetails.balance}
          onChange={handleChangePaymentDetails('balance')}
          size='max'
          className={styles.whiteInput}
        />
      </div>
      <SelectInput
        label='Prestações'
        options={installmentsOptions}
        selected={String(proposal.paymentDetails.installments)}
        onChange={() => null}
        size='max'
      />
    </section>
  )
}
