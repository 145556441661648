import React from 'react'

import { format } from 'utils/formatters/currency'

import styles from './PropertyValuesSection.module.scss'
export const PropertyValuesSection: React.FC = () => {
  // TODO: Get this values from API
  const property = {
    availablePrice: 213500.0,
    salePrice: 157451.34
  }

  return (
    <section className={styles.propertyValuesSection}>
      <h2>Valores do imóvel</h2>
      <div className={`${styles.card} ${styles.propertyValues}`}>
        <div className={styles.ratedValue}>
          Valor avaliado: <span>{format(property.availablePrice)}</span>
        </div>
        <div className={styles.separator} />
        <div className={styles.saleValue}>
          Valor de venda: <span>{format(property.salePrice)}</span>
        </div>
      </div>
    </section>
  )
}
