import React, { useCallback, useEffect, useState } from 'react'

import { toast } from 'react-toastify'

import { Loader } from 'components'
import { getProfileData } from 'services/api'
import { useUser } from 'store/hooks'
import { changeUser } from 'store/user/actions'

export const AuthWrapper: React.FC = ({ children }) => {
  const { dispatch } = useUser()

  const [loading, setLoading] = useState(false)

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getProfileData()
      dispatch(changeUser(response))
    } catch (error) {
      toast.error('Erro ao carregar dados do usuário')
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    loadData()
  }, [loadData])

  if (loading) {
    return <Loader />
  }

  return <>{children}</>
}
