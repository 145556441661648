import React from 'react'

export type SearchProps = {
  width?: string
  height?: string
  color?: string
  onClick?: () => void
}

export const SearchCircle: React.FC<SearchProps> = ({
  width = 64,
  height = 64,
  color = 'white',
  onClick
}) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='32' cy='32' r='32' fill='black' fillOpacity='0.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.6002 19.2C35.344 19.2 40.0002 23.8562 40.0002 29.6C40.0002 31.8945 39.2571 34.0155 37.9984 35.7355L44.057 41.7941C44.6819 42.4189 44.6819 43.432 44.057 44.0568C43.4322 44.6816 42.4191 44.6816 41.7943 44.0568L35.7357 37.9982C34.0158 39.2569 31.8948 40 29.6002 40C23.8564 40 19.2002 35.3437 19.2002 29.6C19.2002 23.8562 23.8564 19.2 29.6002 19.2ZM29.6002 22.4C25.6237 22.4 22.4002 25.6235 22.4002 29.6C22.4002 33.5764 25.6237 36.7999 29.6002 36.7999C33.5766 36.7999 36.8002 33.5764 36.8002 29.6C36.8002 25.6235 33.5766 22.4 29.6002 22.4Z'
        fill={color}
      />
    </svg>
  )
}
