type ReturnProps = {
  [key: string]: {
    id: string
    option: string
  }[]
}

export function getOptionsMessages(): ReturnProps {
  return {
    informationsOptions: [
      {
        id: 'sms',
        option: 'SMS'
      },
      {
        id: 'email',
        option: 'E-mail'
      },
      {
        id: 'whatsapp',
        option: 'Whatsapp'
      },
      {
        id: 'pushNotification',
        option: 'Push notification'
      }
    ],
    promotionsOptions: [
      {
        id: 'sms',
        option: 'SMS'
      },
      {
        id: 'email',
        option: 'E-mail'
      },
      {
        id: 'whatsapp',
        option: 'Whatsapp'
      },
      {
        id: 'pushNotification',
        option: 'Push notification'
      }
    ]
  }
}
