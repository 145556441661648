import { UserModel } from '_types'

import * as UserTypes from './types'

export function changeUser(user: UserModel): UserTypes.Actions {
  return {
    type: UserTypes.Types.UPDATE_USER,
    payload: {
      user
    }
  }
}

export function changeNotifionsOptions(
  field: UserTypes.StatesMessages,
  innerField: UserTypes.InnerFieldMessages
): UserTypes.Actions {
  return {
    type: UserTypes.Types.CHANGE_NOTIFICATION,
    payload: {
      field,
      innerField
    }
  }
}
