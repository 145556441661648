import React, { ChangeEvent, useState } from 'react'

import { IoIosCheckmarkCircle, IoIosClose, IoIosSearch } from 'react-icons/io'

import { FileType } from '_types'
import helpIcon from 'assets/svg/ajuda.svg'
import uploadIcon from 'assets/svg/upload.svg'
import { ConfirmModal } from 'components/confirmModal/ConfirmModal'

import styles from './DocumentCard.module.scss'

export type DocumentCardProps = {
  title: string
  status?: string
  onChangeFiles: (files: Array<FileType>) => void
  files: Array<FileType>
}

export const DocumentCard: React.FC<DocumentCardProps> = ({
  title,
  status,
  files,
  children,
  onChangeFiles
}) => {
  const [fileToExclude, setFileToExclude] = useState('')

  function onChangeFileInput(
    event: ChangeEvent<HTMLInputElement>,
    subtitle: string
  ) {
    const newDocument = event.target.files

    if (!newDocument) return
    const newCurrentFiles = [...files]

    const pathImage = URL.createObjectURL(newDocument[0])
    const imageName = newDocument[0].name

    const fileIndex = files.findIndex(file => file.subtitle === subtitle)

    newCurrentFiles[fileIndex].path = pathImage
    newCurrentFiles[fileIndex].name = imageName
    newCurrentFiles[fileIndex].subtitle = subtitle
    onChangeFiles(newCurrentFiles)
  }

  function onClickToRemoveFile() {
    setFileToExclude('')

    if (!fileToExclude) return

    const fileIndex = files.findIndex(file => file.name === fileToExclude)

    const draftState = [...files]

    draftState[fileIndex].name = ''
    draftState[fileIndex].path = ''

    onChangeFiles(draftState)
  }

  return (
    <div className={styles.container}>
      <header>
        <p>{title}</p>
      </header>
      <div className={styles.card}>
        {files.map(file => (
          <div className={styles.cardContent} key={file.subtitle}>
            <div className={styles.title}>
              <span>{file.subtitle}</span>
              <img src={helpIcon} alt='Imagem de ajuda' />
            </div>
            {file.path ? (
              <label className={styles.uploadFileEnd}>
                <img src={file.path} alt={title} />
                <div className={styles.documentActions}>
                  <div
                    data-testid='documentCloseAction'
                    onClick={() => setFileToExclude(file.name)}
                  >
                    <IoIosClose size={40} color='#fff' />
                  </div>
                  <div
                    data-testid='documentOpenAction'
                    onClick={() => console.log('visualizar')}
                  >
                    <IoIosSearch size={30} color='#fff' />
                  </div>
                </div>
                {status === 'Aprovado' && (
                  <div className={styles.documentStatus}>
                    <IoIosCheckmarkCircle size={20} color='#00bfb2' />
                    <p>Documento aprovado</p>
                  </div>
                )}
              </label>
            ) : (
              <label className={styles.uploadFile}>
                <img src={uploadIcon} alt='Imagem de upload de arquivos' />
                <span>
                  Arraste e solte um arquivo ou clique para selecionar no
                  computador
                </span>
                <input
                  data-testid='inputFiles'
                  type='file'
                  accept='image/*'
                  onChange={e => onChangeFileInput(e, file.subtitle)}
                />
              </label>
            )}
          </div>
        ))}
        {children}
      </div>

      <ConfirmModal
        data-testid='confirmModal'
        isOpen={!!fileToExclude}
        onCancel={() => setFileToExclude('')}
        onConfirm={onClickToRemoveFile}
      />
    </div>
  )
}
