import React from 'react'

import classnames from 'classnames'

import styles from './ProposalTimeline.module.scss'

type DataItem = { id: string; date: string; title: string; status: string }

export type ProposalTimelineProps = {
  data: DataItem[]
  onClick: (item: DataItem) => void
}

export const ProposalTimeline: React.FC<ProposalTimelineProps> = ({
  data,
  onClick
}) => {
  return (
    <main>
      <section className={styles.container}>
        {data.map((item, index) => {
          const accepted = item.status === 'accepted'
          const disapproved = item.status === 'disapproved'
          const lastIndex = data.length - 1
          const nextIndex = index + 1
          const isAcceptedSeparator =
            index === lastIndex ? false : data[nextIndex].status === 'accepted'

          return (
            <article className={styles.item} key={item.id}>
              <p className={styles.date}>{item.date}</p>
              <div className={styles.timeline}>
                <div className={styles.timelineItem}>
                  <div
                    className={classnames(styles.icon, {
                      [styles.accepted]: accepted,
                      [styles.disapproved]: disapproved
                    })}
                  />
                </div>
                <div
                  className={classnames(styles.separator, {
                    [styles.accepted]: isAcceptedSeparator,
                    [styles.disapproved]: disapproved
                  })}
                />
              </div>
              <p
                className={classnames(styles.title, {
                  [styles.bold]: disapproved,
                  [styles.initial]: !item.status
                })}
              >
                {item.title}
                {disapproved && (
                  <button
                    className={styles.option}
                    onClick={() => onClick(item)}
                  >
                    Ver detalhes
                  </button>
                )}
              </p>
            </article>
          )
        })}
      </section>
    </main>
  )
}
