import { FileType, GenericListDocuments, ProposalModel } from '_types'

export type State = ProposalModel

export enum Types {
  UPDATE_PROPOSAL = 'UPDATE_PROPOSAL',
  UPDATE_PERSONAL_DOCUMENTS = 'UPDATE_PERSONAL_DOCUMENTS',
  UPDATE_SPOUSE_DOCUMENTS = 'UPDATE_SPOUSE_DOCUMENTS',
  UPDATE_COMPLEMENTARY_DOCUMENTS = 'UPDATE_COMPLEMENTARY_DOCUMENTS'
}

export type Payload = {
  [Types.UPDATE_PROPOSAL]: {
    proposal: ProposalModel
  }
  [Types.UPDATE_PERSONAL_DOCUMENTS]: {
    idProponent: string
    documentKey: keyof GenericListDocuments
    files: FileType[]
  }
  [Types.UPDATE_SPOUSE_DOCUMENTS]: {
    idProponent: string
    documentKey: keyof GenericListDocuments
    files: FileType[]
  }
  [Types.UPDATE_COMPLEMENTARY_DOCUMENTS]: {
    idProponent: string
    files: FileType[]
  }
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>]
