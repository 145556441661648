import React from 'react'

import { mockProponent } from '_mocks'
import perfil from 'assets/svg/perfil.svg'
import { AddProponent, Proponent } from 'components'

import proponentContainer from '../../1_proponentContainer/ProponentContainer.module.scss'
import styles from './ProponentTutorialContainer.module.scss'

export type ProponentTutorialContainerProps = {
  isVisible: boolean
}

export const ProponentTutorialContainer: React.FC<ProponentTutorialContainerProps> = ({
  isVisible
}) => {
  return (
    <div className={proponentContainer.container}>
      <div className={proponentContainer.title}>
        <img src={perfil} alt='Perfil icon' />
        <h1>Proponentes</h1>
      </div>

      <p className={proponentContainer.description}>
        Para continuar com sua proposta, finalize o cadastro de proponente
        principal.
      </p>

      <p className={proponentContainer.description}>
        Para adicionar proponentes, clique em
        <strong>"Adicionar Proponente"</strong>.
      </p>
      <div className={proponentContainer.proponentsContainer}>
        <Proponent proponent={mockProponent()} />

        <div className={isVisible ? styles.highlight : ''}>
          <AddProponent />
        </div>
      </div>
    </div>
  )
}
