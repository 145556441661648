import { emptyProposal } from '_mocks'

import * as ProposalTypes from './types'

export const InitialState: ProposalTypes.State = emptyProposal()

export function reducer(
  state = InitialState,
  action: ProposalTypes.Actions
): ProposalTypes.State {
  switch (action.type) {
    case ProposalTypes.Types.UPDATE_PROPOSAL:
      return { ...state, ...action.payload.proposal }
    case ProposalTypes.Types.UPDATE_PERSONAL_DOCUMENTS: {
      const draft = { ...state }
      const { documentKey, idProponent } = action.payload

      const findedIndex = draft.proponents.findIndex(
        ({ id }) => id === idProponent
      )
      if (findedIndex < 0) return state
      const findProponent = draft.proponents[findedIndex]
      findProponent.personalDocuments[documentKey].files = action.payload.files
      return draft
    }
    case ProposalTypes.Types.UPDATE_SPOUSE_DOCUMENTS: {
      const draft = { ...state }
      const { documentKey, idProponent } = action.payload

      const findedIndex = draft.proponents.findIndex(
        ({ id }) => id === idProponent
      )
      if (findedIndex < 0) return state
      const findProponent = draft.proponents[findedIndex]
      findProponent.spouseDocuments[documentKey].files = action.payload.files
      return draft
    }
    case ProposalTypes.Types.UPDATE_COMPLEMENTARY_DOCUMENTS: {
      const draft = { ...state }
      const { idProponent } = action.payload

      const findedIndex = draft.proponents.findIndex(
        ({ id }) => id === idProponent
      )
      if (findedIndex < 0) return state
      const findProponent = draft.proponents[findedIndex]
      findProponent.complementaryDocuments.files = action.payload.files
      return draft
    }
    default:
      return state
  }
}
