import faker from '@faker-js/faker'

import { PropertyModel } from '_types'

export const mockProperty = (): PropertyModel => ({
  id: '15454574748',
  name: 'Lote, Comercial',
  photo: faker.image.image(),
  photoArray: [faker.image.image(), faker.image.image(), faker.image.image()],
  longPhoto:
    'https://images.resale.com.br/b69cde8a-97ee-0a17-69ec-5f0f67752ebe',
  address:
    'Avenida São Paulo, , Lote 18, Quadra 165, Cidade Nova, Eixo dos Gerais, Balsas, MA, 65800000',
  appraisedLabel: 'Valor avaliado',
  appraisedValue: 69250,
  category: undefined,
  city: 'Balsas',
  desagio: 34,
  description:
    'Lote, Desocupado, 750.00 M² de área de terreno. Matrícula nº 14578, 1º CRI DE BALSAS, Inscrição Prefeitura 01.10.0165.0018.000. Valor avaliado R$69.250,00.',
  features: [
    {
      id: 0,
      feature: faker.random.arrayElement([
        'Dormitório(s)',
        'Suíte(s)',
        'Vaga(s) na Garagem'
      ]),
      amount: '1',
      icon: 'bed'
    },
    { id: 1, feature: 'Suíte(s)', amount: '2', icon: 'Suite' },
    { id: 2, feature: 'Vaga(s) na Garagem', amount: '3', icon: 'vacancies' }
  ],
  saleLabel: 'Valor de venda',
  saleValue: 45887,
  soldStatus: 'ativo',
  state: 'MA',
  tags: ['IDR161199', 'Desocupado', 'Venda direta', 'Novidade'],
  bank: '4dd4393a-2318-4576-8d8f-56b25c5c0e3a',
  vendorId: '411d41d1-deec-2a2f-e83f-5f4ea405d045',
  vendorName: '1'
})
