import React from 'react'

import successIcon from 'assets/svg/sucesso.svg'
import { Modal, Button } from 'components'

import styles from './ProposalSuccessfullySignedModal.module.scss'

export type ProposalSuccessfullySignedModalProps = {
  isOpen: boolean
  closeModal: () => void
}

export const ProposalSuccessfullySignedModal: React.FC<ProposalSuccessfullySignedModalProps> = ({
  isOpen,
  closeModal
}) => {
  const handleContinue = () => {
    closeModal()
  }
  return (
    <Modal
      typeModal={'normal'}
      isOpen={isOpen}
      closeModal={closeModal}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <img src={successIcon} alt='Icone de sucesso' />
        <h1>Proposta assinada com sucesso!</h1>
        <p>
          Sua proposta foi assinada com sucesso e enviada para avaliação. Para
          finalizar o processo precisamos que você nos envie a documentação que
          está faltando.
        </p>
        <strong>Vamos lá?</strong>
        <Button
          label='Continuar'
          onClick={handleContinue}
          className={styles.btnContinue}
        />
      </div>
    </Modal>
  )
}
