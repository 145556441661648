import * as mock from './mock'
import {
  ListProponents,
  CreateProponent,
  UpdateProponent,
  DeleteProponent,
  GetProponentData
} from './types'

export const listProponents: ListProponents = mock.listProponents
export const getProponentData: GetProponentData = mock.getProponentData
export const createProponent: CreateProponent = mock.createProponent
export const updateProponent: UpdateProponent = mock.updateProponent
export const deleteProponent: DeleteProponent = mock.deleteProponent
