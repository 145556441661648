export const genderOptions = [
  {
    name: 'Feminino',
    value: 'female'
  },
  {
    name: 'Masculino',
    value: 'male'
  },
  {
    name: 'Outro',
    value: 'other'
  }
]

export const civilStatusOptions = [
  {
    name: 'Solteiro',
    value: 'not_married'
  },
  {
    name: 'Casado',
    value: 'married'
  },
  {
    name: 'Divorciado',
    value: 'divorced'
  },
  {
    name: 'Separado judicialmente',
    value: 'separate'
  },
  {
    name: 'Viúvo',
    value: 'widower'
  },
  {
    name: 'União Estável',
    value: 'stable_union'
  }
]

export const maritalPropertySystemsOptions = [
  {
    name: 'Solteiro',
    value: 'not_married'
  },
  {
    name: 'Casado',
    value: 'married'
  },
  {
    name: 'Divorciado',
    value: 'divorced'
  },
  {
    name: 'Separado judicialmente',
    value: 'separate'
  },
  {
    name: 'Viúvo',
    value: 'widower'
  },
  {
    name: 'União Estável',
    value: 'stable_union'
  }
]

export const ufOptions = [
  { name: 'Acre', value: 'AC' },
  { name: 'Alagoas', value: 'AL' },
  { name: 'Amapá', value: 'AP' },
  { name: 'Amazonas', value: 'AM' },
  { name: 'Bahia', value: 'BA' },
  { name: 'Ceará', value: 'CE' },
  { name: 'Distrito Federal', value: 'DF' },
  { name: 'Espírito Santo', value: 'ES' },
  { name: 'Goiás', value: 'GO' },
  { name: 'Maranhão', value: 'MA' },
  { name: 'Mato Grosso', value: 'MT' },
  { name: 'Mato Grosso do Sul', value: 'MS' },
  { name: 'Minas Gerais', value: 'MG' },
  { name: 'Paraná', value: 'PR' },
  { name: 'Paraíba', value: 'PB' },
  { name: 'Pará', value: 'PA' },
  { name: 'Pernambuco', value: 'PE' },
  { name: 'Piauí', value: 'PI' },
  { name: 'Rio Grande do Norte', value: 'RN' },
  { name: 'Rio Grande do Sul', value: 'RS' },
  { name: 'Rio de Janeiro', value: 'RJ' },
  { name: 'Rondônia', value: 'RO' },
  { name: 'Roraima', value: 'RR' },
  { name: 'Santa Catarina', value: 'SC' },
  { name: 'Sergipe', value: 'SE' },
  { name: 'São Paulo', value: 'SP' },
  { name: 'Tocantins', value: 'TO' }
]

// TODO: define correct payment types
export const paymentOptions = [
  { name: 'Cartão de crédito', value: 'credit_card' }
]

// TODO: define correct installments
export const installmentsOptions = [{ name: '1x', value: '1' }]
