import faker from '@faker-js/faker'

import {
  DocumentStatusType,
  FileType,
  GenericDocument,
  GenericListDocuments
} from '_types'

export const emptyFileType = (props: Partial<FileType> = {}): FileType => ({
  subtitle: '',
  path: '',
  name: '',
  ...props
})

export const mockFileType = (props: Partial<FileType> = {}): FileType => ({
  subtitle: faker.commerce.productName(),
  path: faker.image.unsplash.avatar(),
  name: faker.company.companyName(),
  ...props
})

export const emptyGenericDocument = (
  props: Partial<GenericDocument> = {}
): GenericDocument => ({
  title: '',
  status: 'pending',
  files: [],
  ...props
})

export const mockGenericDocument = (
  props: Partial<GenericDocument> = {}
): GenericDocument => ({
  title: faker.company.companyName(),
  status: faker.random.arrayElement(['pending', 'approved', 'reproved']),
  files: [
    {
      name: faker.commerce.productName(),
      path: faker.image.abstract(),
      subtitle: faker.address.cityName()
    }
  ],
  ...props
})

export const emptyGenericListDocument = (): GenericListDocuments => ({
  cpf: emptyGenericDocument({
    title: 'CPF',
    files: [
      emptyFileType({ subtitle: 'Frente' }),
      emptyFileType({ subtitle: 'Verso' })
    ]
  }),
  identityDocument: emptyGenericDocument({
    title: 'RG',
    files: [
      emptyFileType({ subtitle: 'Frente' }),
      emptyFileType({ subtitle: 'Verso' })
    ]
  }),
  selfieDocument: emptyGenericDocument({
    title: 'Selfie com documento',
    files: [emptyFileType({ subtitle: 'Selfie com RG/CNH' })]
  }),
  residentialCertificate: emptyGenericDocument({
    title: 'Comprovante de residência',
    files: [emptyFileType({ subtitle: 'Comprovante de residência' })]
  }),
  birthCertificate: emptyGenericDocument({
    title: 'Certidão de nascimento',
    files: [emptyFileType({ subtitle: 'Certidão de nascimento' })]
  })
})

export const mockGenericListDocument = (): GenericListDocuments => ({
  cpf: mockGenericDocument({
    title: 'CPF',
    files: [
      mockFileType({ subtitle: 'Frente' }),
      mockFileType({ subtitle: 'Verso' })
    ]
  }),
  identityDocument: mockGenericDocument({
    title: 'RG',
    files: [
      mockFileType({ subtitle: 'Frente' }),
      mockFileType({ subtitle: 'Verso' })
    ]
  }),
  selfieDocument: mockGenericDocument({
    title: 'Selfie com documento',
    files: [mockFileType({ subtitle: 'Selfie com RG/CNH' })]
  }),
  residentialCertificate: mockGenericDocument({
    title: 'Comprovante de residência',
    files: [mockFileType({ subtitle: 'Comprovante de residência' })]
  }),
  birthCertificate: mockGenericDocument({
    title: 'Certidão de nascimento',
    files: [mockFileType({ subtitle: 'Certidão de nascimento' })]
  })
})

export const emptyDocumentStatusType = (): DocumentStatusType => ({
  progress: 0,
  status: 'incomplete',
  proponents: [
    { title: 'Selfie com RG / CNH', status: 'pending', files: [] },
    { title: 'Comprovante de residência', status: 'pending', files: [] },
    { title: 'Certidão de nascimento', status: 'pending', files: [] },
    { title: 'CPF', status: 'pending', files: [] },
    { title: 'RG / CNH', status: 'pending', files: [] }
  ],
  spouse: [
    { title: 'Selfie com RG / CNH', status: 'pending', files: [] },
    { title: 'Comprovante de residência', status: 'pending', files: [] },
    { title: 'Certidão de nascimento', status: 'pending', files: [] },
    { title: 'CPF', status: 'pending', files: [] },
    { title: 'RG / CNH', status: 'pending', files: [] }
  ],
  complementary: [
    { title: 'Documento X', status: 'pending', reason: '', files: [] },
    { title: 'Documento Y', status: 'pending', files: [] }
  ]
})

export const mockDocumentStatusType = (): DocumentStatusType => ({
  progress: 0,
  status: 'incomplete',
  proponents: [
    { title: 'Selfie com RG / CNH', status: 'approved', files: [] },
    { title: 'Comprovante de residência', status: 'pending', files: [] },
    {
      title: 'Certidão de nascimento',
      status: 'reproved',
      reason: 'documento "certidão_nascimento.png" está ilegível',
      files: []
    },
    { title: 'CPF', status: 'approved', files: [] },
    {
      title: 'RG / CNH',
      status: 'reproved',
      reason: 'documento "cnh01.png" está vencida',
      files: []
    }
  ],
  spouse: [
    { title: 'Selfie com RG / CNH', status: 'approved', files: [] },
    { title: 'Comprovante de residência', status: 'approved', files: [] },
    { title: 'Certidão de nascimento', status: 'approved', files: [] },
    {
      title: 'CPF',
      status: 'reproved',
      reason: '"re-verso.jpg" está ilegível',
      files: []
    },
    { title: 'RG / CNH', status: 'approved', files: [] }
  ],
  complementary: [
    { title: 'Documento X', status: 'approved', reason: '', files: [] },
    { title: 'Documento Y', status: 'approved', files: [] }
  ]
})
