import React, { useState, useEffect } from 'react'

import styles from './Tutorial.module.scss'

export type ForwardedTutorialProps = Partial<{
  hideTutorial: () => void
  isLastStep: boolean
  handleNextStep: () => void
  totalSteps: number
  actualStep: number
}>

export type TutorialProps = {
  isVisible: boolean
  hideTutorial: () => void
}

export const Tutorial: React.FC<TutorialProps> = React.memo(
  ({ children, isVisible, hideTutorial }) => {
    const [actualStep, setActualStep] = useState(0)
    const [leaving, setLeaving] = useState<number | null>(null)
    const totalSteps = React.Children.count(children)
    const isLastStep = totalSteps === actualStep + 1

    useEffect(() => {
      if (!isVisible) {
        setActualStep(0)
        setLeaving(null)
      }
    }, [isVisible])

    const handleStartLeaving = () => {
      setLeaving(actualStep)
    }

    const handleLeavingEnded = () => {
      handleNextStep()
    }

    const handleNextStep = () => {
      setActualStep(oldStep => {
        if (oldStep + 1 !== totalSteps) {
          return oldStep + 1
        }
        return oldStep
      })
    }

    const handleWrapperStyle = (index: number) =>
      `${styles.wrapper} ${
        actualStep === index ? styles.active : styles.hidden
      } ${leaving === index ? styles.leaving : ''}`

    const forwardRefs: ForwardedTutorialProps = {
      hideTutorial,
      isLastStep,
      handleNextStep: handleStartLeaving,
      totalSteps,
      actualStep
    }

    return isVisible ? (
      <div className={styles.base}>
        <div className={styles.overlay} onClick={hideTutorial} />
        {React.Children.map(
          children,
          (node, index) =>
            React.isValidElement(node) && (
              <div
                className={handleWrapperStyle(index)}
                onAnimationEnd={handleLeavingEnded}
              >
                {React.cloneElement(node, forwardRefs)}
              </div>
            )
        )}
      </div>
    ) : null
  }
)
Tutorial.displayName = 'Tutorial'
