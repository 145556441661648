import { faker } from '@faker-js/faker'

import { ProponentModel } from '_types'

import {
  emptyDocumentStatusType,
  emptyGenericDocument,
  emptyGenericListDocument,
  mockDocumentStatusType,
  mockGenericDocument,
  mockGenericListDocument
} from './documents'

export const emptyProponent = (): ProponentModel => ({
  id: '',
  name: '',
  email: '',
  cpf: '',
  cnpj: '',
  rg: '',
  address: '',
  phone: '',
  personType: 'pf',
  emitter: '',
  birthday_date: '',
  occupation: '',
  nationality: '',
  naturalness: '',
  gender: '',
  telephone: '',
  whatsapp: '',
  street: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  uf: '',
  postal_code: '',
  civil_status: '',
  marital_property_systems: '',
  company_name: '',
  contribution_date: '',
  activity: '',
  situation: '',
  spouse: {
    name: '',
    cpf: '',
    rg: '',
    emitter: '',
    birthday_date: '',
    occupation: '',
    nationality: '',
    naturalness: '',
    gender: '',
    whatsapp: '',
    email: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    postal_code: '',
    use_proponent_address: false
  },
  witness: {
    name: '',
    cpf: '',
    rg: '',
    email: ''
  },
  personalDocuments: emptyGenericListDocument(),
  spouseDocuments: emptyGenericListDocument(),
  complementaryDocuments: emptyGenericDocument(),
  statusDocumentation: emptyDocumentStatusType()
})

export const mockProponent = (): ProponentModel => ({
  ...emptyProponent(),
  personType: faker.random.arrayElement(['pf', 'pj']),
  id: faker.datatype.uuid(),
  name: faker.name.findName(),
  email: faker.internet.email(),
  cpf: faker.datatype.number().toString(),
  rg: '323290875',
  emitter: 'SSP',
  birthday_date: '12/05/1980',
  occupation: 'Dev',
  nationality: faker.address.country(),
  naturalness: faker.address.cityName(),
  gender: faker.random.arrayElement(['male', 'female', 'other']),
  telephone: faker.phone.phoneNumberFormat(),
  whatsapp: faker.phone.phoneNumberFormat(),
  phone: faker.datatype.number().toString(),
  street: faker.address.streetName(),
  number: faker.datatype.number({ min: 0, max: 100 }).toString(),
  complement: faker.random.word(),
  neighborhood: faker.random.word(),
  city: faker.address.cityName(),
  uf: faker.random.arrayElement(['BA', 'MG', 'SP']),
  postal_code: faker.address.zipCode(),
  civil_status: 'not_married',
  personalDocuments: mockGenericListDocument(),
  spouseDocuments: mockGenericListDocument(),
  complementaryDocuments: mockGenericDocument(),
  statusDocumentation: {
    ...mockDocumentStatusType(),
    progress: faker.datatype.number({
      min: 0,
      max: 100
    }),
    status: faker.random.arrayElement([
      'approved',
      'reproved',
      'finished',
      'incomplete'
    ])
  }
})
