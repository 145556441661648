import { useMemo } from 'react'

import useStore from 'store'

import { State as ProposalState } from './proposal/types'
import { State as UserState } from './user/types'

const useMemoStore = () => {
  const { state, dispatch: d } = useStore()
  // eslint-disable-next-line
  const dispatch = useMemo(() => d, [])
  return { state, dispatch }
}

export const useProposal = (): {
  proposal: ProposalState
  dispatch: StoreTypes.ActionOrThunk
} => {
  const { state, dispatch } = useMemoStore()
  return { proposal: state.proposal, dispatch }
}

export const useUser = (): {
  user: UserState
  dispatch: StoreTypes.ActionOrThunk
} => {
  const { state, dispatch } = useMemoStore()
  return { user: state.user, dispatch }
}
