import React from 'react'

import failedIcon from 'assets/svg/failed-bg.svg'
import { Modal, Button } from 'components'

import styles from './DocValidationDisapprovedModal.module.scss'

export type DocValidationDisapprovedModalProps = {
  isOpen: boolean
  closeModal: () => void
}

export const DocValidationDisapprovedModal: React.FC<DocValidationDisapprovedModalProps> = ({
  isOpen,
  closeModal
}) => {
  return (
    <Modal
      typeModal='small'
      isOpen={isOpen}
      closeModal={closeModal}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <header>
          <img src={failedIcon} alt='Icone falha na validação' />
          <h1 className={styles.title}>Documento reprovado</h1>
        </header>
        <p className={styles.text}>
          Confira se a foto não está cortada ou desfocada e tente enviar
          novamente.
        </p>
        <footer>
          <Button
            className={styles.btnContinue}
            label='Tentar novamente'
            onClick={closeModal}
          />
        </footer>
      </div>
    </Modal>
  )
}
