import React from 'react'

import { BsFillQuestionCircleFill } from 'react-icons/bs'

import { Input } from 'components'
import { useProposal } from 'store/hooks'
import { format } from 'utils/formatters/currency'

import styles from './FeesDetailsSection.module.scss'

type FeesDetailsSectionProps = {
  handleChangeProposal: (key: string) => (value: string) => void
}

export const FeesDetailsSection: React.FC<FeesDetailsSectionProps> = ({
  handleChangeProposal
}) => {
  const { proposal } = useProposal()

  return (
    <section className={styles.howFessWillBePaidSection}>
      <h2>Como será pago os honorários?</h2>
      {proposal.fees.map((item, index) => (
        <div key={index} className={styles.details}>
          <div>
            <p>
              Valor da comissão <BsFillQuestionCircleFill color='#00BFB2' />
            </p>
            <span>{format(item.comissionValue)}</span>
          </div>
          <hr />
          <div>
            <p>Quem paga?</p>
            <span>{item.whoPay}</span>
          </div>
          <hr />
          <div>
            <p>Para quem?</p>
            <span>{item.target}</span>
          </div>
          <hr />
          <div>
            <p>Quando?</p>
            <span>{item.when}</span>
          </div>
        </div>
      ))}
      <Input
        className={styles.whiteInput}
        type='textarea'
        label='Gostaria de incluir observações'
        value={proposal.observations}
        size='max'
        onChange={handleChangeProposal('observations')}
        rows={8}
      />
    </section>
  )
}
