/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  isValidCPF,
  isValidCNPJ,
  isValidCEP,
  isValidPhone as validPhone,
  isValidEmail as validEmail
} from '@brazilian-utils/brazilian-utils'
import { isValid } from 'date-fns'

export const isValidCpf = (cpf: string): boolean => {
  return isValidCPF(cpf)
}

export const isValidCnpj = (cnpj: string): boolean => {
  return isValidCNPJ(cnpj)
}

export const isValidBirthdayDate = (stringDate: string): boolean => {
  const [day, month, year] = stringDate.split('/')
  const date = `${month}/${day}/${year}`

  const userBirthDate = new Date(date)

  const userBirthYear = userBirthDate.getFullYear()

  const currentDate = new Date()

  const isOverSixteen =
    currentDate >=
    new Date(
      userBirthDate.getFullYear() + 16,
      userBirthDate.getMonth(),
      userBirthDate.getDate()
    )

  if (
    date.length > 9 &&
    userBirthYear > 1900 &&
    userBirthYear <= currentDate.getFullYear() &&
    isOverSixteen
  ) {
    return isValid(new Date(date))
  }
  return false
}

export const isValidCep = (cep: string): boolean => {
  const parsedCep = cep.replace('-', '').replace('.', '')
  if (parsedCep.length > 8 || parsedCep.length < 8) {
    return false
  }

  return isValidCEP(parsedCep)
}

export const isValidPhone = (phone: string): boolean => {
  const parsedPhone = phone
    .replace('+', '')
    .replace('-', '')
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
  return validPhone(parsedPhone)
}

export const isValidEmail = (email: string): boolean => {
  return validEmail(email)
}

export const isValidFullName = (name: string): boolean => {
  const parsedName = name.split(' ')
  const hasNumbers = /\d+/.test(name)
  if (parsedName.length >= 2 && !hasNumbers) return true
  return false
}

export const isValidRg = (rg: string): boolean => {
  return rg.length > 9
}

export const personFormIsValid = (proponent_form: any): boolean => {
  const {
    personType,
    name,
    cpf,
    rg,
    emitter,
    birthday_date,
    occupation,
    nationality,
    naturalness,
    gender,
    telephone,
    whatsapp,
    email,
    street,
    number,
    neighborhood,
    city,
    uf,
    postal_code,
    civil_status,
    marital_property_systems,
    company_name,
    cnpj,
    contribution_date,
    activity
  } = proponent_form

  const baseDataIsValid =
    !isValidPhone(telephone.toString()) ||
    !isValidPhone(whatsapp.toString()) ||
    !isValidCep(postal_code) ||
    !street ||
    !number ||
    !neighborhood ||
    !city ||
    !uf

  const personIsValid =
    baseDataIsValid ||
    !isValidFullName(name) ||
    !isValidCpf(cpf.toString()) ||
    !isValidRg(rg.toString()) ||
    !isValidBirthdayDate(birthday_date) ||
    !isValidEmail(email) ||
    !emitter ||
    !occupation ||
    !nationality ||
    !naturalness ||
    !gender ||
    !civil_status ||
    (civil_status === 'married' && !marital_property_systems)

  const juridicPersonIsValid =
    !baseDataIsValid ||
    !company_name ||
    !cnpj ||
    !contribution_date ||
    !activity

  const isValid = personType === 'pf' ? personIsValid : juridicPersonIsValid
  return !isValid
}

export const spouseFormIsValid = (proponent_form: any): boolean => {
  const {
    name,
    cpf,
    rg,
    emitter,
    birthday_date,
    occupation,
    nationality,
    naturalness,
    gender,
    whatsapp,
    email
  } = proponent_form.spouse

  const {
    street,
    number,
    complement,
    neighborhood,
    city,
    uf,
    postal_code
  } = proponent_form.spouse.use_proponent_address
    ? proponent_form
    : proponent_form.spouse

  const spouseIsValid =
    !isValidPhone(whatsapp.toString()) ||
    !isValidCep(postal_code) ||
    !isValidFullName(name) ||
    !isValidCpf(cpf.toString()) ||
    !isValidRg(rg.toString()) ||
    !isValidBirthdayDate(birthday_date) ||
    !isValidEmail(email) ||
    !emitter ||
    !occupation ||
    !nationality ||
    !naturalness ||
    !gender ||
    !street ||
    !number ||
    !complement ||
    !neighborhood ||
    !city ||
    !uf

  return !spouseIsValid
}

export const witnessFormIsValid = (proponent_form: any): boolean => {
  const { name, cpf, rg, email } = proponent_form.witness

  const witnessIsValid =
    !isValidFullName(name) ||
    !isValidCpf(cpf.toString()) ||
    !isValidRg(rg.toString()) ||
    !isValidEmail(email)

  return !witnessIsValid
}

export const validationRules = {
  name: ['Preencher nome completo', 'números não são permitidos'],
  cpf: ['Digite um CPF válido', 'CPF único'],
  rg: ['Digite um RG válido'],
  emitter: ['Apenas letras'],
  birthDate: ['Deve ser maior de 16 anos']
}
