import React from 'react'

import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import WebFont from 'webfontloader'

import 'react-toastify/dist/ReactToastify.css'

import 'assets/styles/index.scss'
import { AuthWrapper } from 'pages'

import { Router } from './router'
import { StoreProvider } from './store'
import StateInspectorComponent from './store/StateInspector'

WebFont.load({
  google: {
    families: ['Titillium Web:200,200i,300,300i,400,400i,600,600i,700,700i,900']
  }
})

ReactDOM.render(
  <React.StrictMode>
    <StateInspectorComponent>
      <StoreProvider>
        <AuthWrapper>
          <Router />
        </AuthWrapper>
        <ToastContainer />
      </StoreProvider>
    </StateInspectorComponent>
  </React.StrictMode>,
  document.getElementById('root')
)
