import { freeze } from 'utils/freeze/freeze'

import { GetTypeDeclarations } from './types'

export const getTypeDeclarations: GetTypeDeclarations = async () => {
  await freeze()
  return [
    {
      name: 'Aplicação Financeira',
      value: 'financialApplication'
    }
  ]
}
