import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import {
  DropzoneCard,
  Button,
  ValidatingDocModal,
  DocValidationSuccessModal,
  DocValidationDisapprovedModal
} from 'components'
import { useFiles } from 'pages/filesUpload/hooks/useFiles'
import { NEW_RESOURCE_KEY, Routes } from 'router/routes'

import styles from './documentUploadContainer.module.scss'

export const DocumentUploadContainer: React.FC = () => {
  const {
    errorMessageBack,
    errorMessageFront,
    isValidatingDocModal,
    isDocValidationSuccess,
    isDocValidationDisapproved,
    setErrorMessageBack,
    setErrorMessageFront,
    setIsValidatingDocModal,
    setIsDocValidationSuccess,
    setIsDocValidationDisapproved,
    onValidateDocs
  } = useFiles()
  const [filesFront, setFilesFront] = useState<File[]>([])
  const [filesBack, setFilesBack] = useState<File[]>([])
  const history = useHistory()
  return (
    <div className={styles.documentationContainer}>
      <h1>Documento pessoal</h1>
      <p>
        Para continuar precisamos que você nos envie uma foto de{' '}
        <strong>frente e verso</strong> do seu documento de identidade.
      </p>

      <div className={styles.cards}>
        <DropzoneCard
          title='Frente'
          messageError={errorMessageFront}
          onAddFiles={files => setFilesFront(files)}
          onRemoveFiles={() => setErrorMessageFront(undefined)}
        />
        <DropzoneCard
          title='Verso'
          messageError={errorMessageBack}
          onAddFiles={files => setFilesBack(files)}
          onRemoveFiles={() => setErrorMessageBack(undefined)}
        />
      </div>

      <div className={styles.buttons}>
        <Button label='Cancelar' typeButton='outline' />
        <Button
          isDisable={!filesFront.length || !filesBack.length}
          label='Concluir envio'
          onClick={() => onValidateDocs([...filesFront, ...filesBack])}
        />
      </div>
      <ValidatingDocModal
        isOpen={isValidatingDocModal}
        closeModal={() => setIsValidatingDocModal(false)}
      />

      <DocValidationDisapprovedModal
        isOpen={isDocValidationDisapproved}
        closeModal={() => setIsDocValidationDisapproved(false)}
      />
      <DocValidationSuccessModal
        isOpen={isDocValidationSuccess}
        closeModal={() => setIsDocValidationSuccess(false)}
        onClick={() => {
          history.push(Routes.PROPOSAL(NEW_RESOURCE_KEY))
          localStorage.setItem('showed_tutorial', 'true')
        }}
        onClickViewTutorial={() => {
          history.push(Routes.PROPOSAL('tutorial'))
          localStorage.removeItem('showed_tutorial')
        }}
      />
    </div>
  )
}
