import React from 'react'

import classnames from 'classnames'
import { GoCheck } from 'react-icons/go'

import atendimento from 'assets/svg/atendimento.svg'
import { useStepValidations } from 'hooks/useStepValidation'

import styles from './StepBar.module.scss'

export type StepBarProps = {
  cursor?: boolean
  step: number
  onClick(id: number): void
  openHelpModal(): void
}
export const StepBar: React.FC<StepBarProps> = ({
  cursor = false,
  step,
  onClick,
  openHelpModal
}) => {
  const { canNavigateToStep } = useStepValidations({ currentStep: step })

  const steps = [
    {
      num: 1,
      description: 'Proponentes',
      disabled: !canNavigateToStep(1)
    },
    {
      num: 2,
      description: 'Proposta',
      disabled: !canNavigateToStep(2)
    },
    {
      num: 3,
      description: 'Assinatura',
      disabled: !canNavigateToStep(3)
    },
    {
      num: 4,
      description: 'Documentação',
      disabled: !canNavigateToStep(4)
    }
  ]
  return (
    <div className={classnames(styles.container, { [styles.cursor]: cursor })}>
      {steps.map(element => {
        const itemContainerClasses = classnames({
          [styles.item]: true,
          [styles.green]: element.num <= step,
          [styles.boldFont]: element.num === step,
          [styles.disabled]: !!element.disabled
        })

        return (
          <>
            <div
              key={element.num}
              className={itemContainerClasses}
              onClick={() =>
                !cursor && !element.disabled && onClick(element.num)
              }
            >
              <div>
                {step > element.num ? (
                  <GoCheck size={20} color='#ffff' />
                ) : (
                  element.num
                )}
              </div>
              <p>{element.description}</p>
            </div>
            {element.num !== 4 && (
              <div
                className={classnames(
                  styles.verticalLine,
                  styles[`verticalLine-${element.num}` as keyof typeof styles],
                  { [styles.verticalLineGreen]: element.num < step }
                )}
              />
            )}
          </>
        )
      })}
      <div className={styles.help} onClick={() => openHelpModal()}>
        <img src={atendimento} alt='icone de atendimento para ajuda' />
      </div>
    </div>
  )
}
