import React, { useState } from 'react'

import { ClickSignModal, ProposalSuccessfullySignedModal } from 'components'

export const SignatureContainer: React.FC = () => {
  const [modalIsOpen, setModalOpen] = useState(true)
  const [modalSuccess, setModalSuccess] = useState(false)

  return (
    <div>
      <ClickSignModal
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalOpen(false)
          setModalSuccess(prev => !prev)
        }}
      />
      <ProposalSuccessfullySignedModal
        isOpen={modalSuccess}
        closeModal={() => setModalSuccess(prev => !prev)}
      />
    </div>
  )
}
