import React, { useState } from 'react'

import { HelpModal, StepBar } from 'components'

import { helpQuestions } from './helpQuestions'

export type ProposalStepType = 1 | 2 | 3 | 4

export type StepsContainerProps = {
  proponentFormToggle?: boolean
  proposalStep: ProposalStepType
  handleChangeStep: (step: ProposalStepType) => void
}

export const StepContainer: React.FC<StepsContainerProps> = ({
  proponentFormToggle = false,
  proposalStep,
  handleChangeStep
}) => {
  const [helpModalIsOpen, setHelpModalOpen] = useState(false)

  const handleOpenHelp = () => {
    setHelpModalOpen(true)
  }

  return (
    <>
      <StepBar
        cursor={proponentFormToggle}
        step={proposalStep}
        onClick={handleChangeStep}
        openHelpModal={handleOpenHelp}
      />
      <HelpModal
        isOpen={helpModalIsOpen}
        closeModal={() => setHelpModalOpen(false)}
        questions={helpQuestions[proposalStep]}
      />
    </>
  )
}
