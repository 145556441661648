import React, { useState } from 'react'

import { emptyProponent } from '_mocks'
import { ProponentModel } from '_types'
import perfil from 'assets/svg/perfil.svg'
import { AddProponent, Proponent as ProponentComponent } from 'components'
import {
  ModalAddProponents,
  PropertyContainer,
  ProponentsForm
} from 'containers'
import { ModalType } from 'hooks/useToogle'
import { useProposal, useUser } from 'store/hooks'

import proposalStyles from '../../Proposal.module.scss'
import styles from './ProponentContainer.module.scss'

type ProponentContainerProp = {
  proponentFormToggle: ModalType<ProponentModel>
}

export const ProponentContainer: React.FC<ProponentContainerProp> = ({
  proponentFormToggle
}) => {
  const { proposal } = useProposal()
  const { user } = useUser()

  const [open, setOpen] = useState<boolean>(false)

  const userAlreadyRegistered = !!user.proponent.cpf

  const deleteProponent = () => {
    console.log('delete')
  }

  const onClickContinues = (proponet: ProponentModel | undefined) => {
    if (!proponet) return
    console.log(proponet)
    setOpen(false)
  }

  if (proponentFormToggle.isOpen) {
    return (
      <ProponentsForm
        handleClose={proponentFormToggle.close}
        initialData={proponentFormToggle.params}
      />
    )
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <img src={perfil} alt='Perfil icon' />
          <h1>Proponentes</h1>
        </div>
        {!userAlreadyRegistered && (
          <p className={styles.description}>
            Para continuar com sua proposta, finalize o cadastro de proponente
            principal.
          </p>
        )}
        <p className={styles.description}>
          Para adicionar proponentes, clique em
          <strong>"Adicionar Proponente"</strong>.
        </p>
        <div className={styles.proponentsContainer}>
          <ProponentComponent
            key={user.proponent.id}
            onClick={() => proponentFormToggle.open(user.proponent)}
            onDelete={undefined}
            proponent={user.proponent}
          />
          {proposal.proponents.map(proponent => (
            <ProponentComponent
              key={proponent.id}
              onClick={() => proponentFormToggle.open(proponent)}
              onDelete={deleteProponent}
              proponent={proponent}
            />
          ))}
          <AddProponent onClick={() => setOpen(true)} />
        </div>
        <ModalAddProponents
          open={open}
          proponents={proposal.proponents}
          closeModal={() => setOpen(false)}
          onClick={() => proponentFormToggle.open(emptyProponent())}
          onClickContinues={onClickContinues}
        />
      </div>
      <div className={proposalStyles.card}>
        <PropertyContainer />
      </div>
    </>
  )
}
