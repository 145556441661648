type UseStepValidationsParams = {
  currentStep: number
}

type UseStepValidationsReturn = {
  canNavigateToStep: (step: number) => boolean
}

type UseStepValidations = (
  params: UseStepValidationsParams
) => UseStepValidationsReturn

export const useStepValidations: UseStepValidations = ({ currentStep }) => {
  const canNavigateToStep = (step: number) => {
    if (currentStep >= step) return true

    // TODO: add steps data validation
    switch (currentStep) {
      case 1:
        return step === 2
      case 2:
        return step === 3
      case 3:
        return step === 4
      case 4:
        return true
      default:
        return false
    }
  }

  return { canNavigateToStep }
}
