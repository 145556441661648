import React from 'react'

import classnames from 'classnames'

import styles from './ProgressBar.module.scss'

export type ProgressBarProps = {
  progress: number
  className?: string
}
export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  className
}) => {
  const progressStyle = { width: `${progress}%` }

  const wrapperClasses = classnames({
    [styles['progress-wrapper']]: true,
    [className || '']: !!className
  })

  return (
    <div className={wrapperClasses}>
      <span>{progress} % completo</span>
      <div className={styles['progress-bar']}>
        <div className={styles.progress} style={progressStyle}></div>
      </div>
    </div>
  )
}
