import React from 'react'

import { Modal, Button } from 'components'

import logo from '../../assets/svg/logo.svg'
import styles from './DuplicatedProposalModal.module.scss'

export type DuplicatedProposalModalProps = {
  isOpen: boolean
  closeModal: () => void
  onClick: () => void
}

export const DuplicatedProposalModal: React.FC<DuplicatedProposalModalProps> = ({
  isOpen,
  closeModal,
  onClick
}) => {
  return (
    <Modal
      typeModal='small'
      isOpen={isOpen}
      closeModal={closeModal}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <img src={logo} alt='Logo PagImovel' />
        <h1>Proposta em duplicidade</h1>
        <p>Você já tem uma proposta em andamento para esse imóvel</p>
        <p>Deseja retoma-la ou iniciar uma nova ?</p>
        <Button label='Retomar proposta iniciada' onClick={onClick} />
        <Button
          typeButton='underlined'
          label='Iniciar nova proposta'
          onClick={onClick}
        />
      </div>
    </Modal>
  )
}
