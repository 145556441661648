import React from 'react'

import { Modal, Button } from 'components'

import styles from './ConfirmModal.module.scss'

export type ConfirmModalProps = {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onCancel,
  onConfirm
}) => {
  return (
    <Modal
      typeModal='small'
      isOpen={isOpen}
      closeModal={onCancel}
      closeButton={true}
    >
      <div className={styles.container}>
        <h1>Deseja mesmo excluir esse documento ?</h1>
        <p>
          Caso exclua o documento e precise reenviá-lo, será necessário passar
          pelo processo de análise novamente
        </p>
        <div className={styles.buttons}>
          <Button label='Sim, quero excluir mesmo assim' onClick={onConfirm} />
          <Button label='Cancelar' onClick={onCancel} typeButton='underlined' />
        </div>
      </div>
    </Modal>
  )
}
