import React from 'react'

import { HiPlus } from 'react-icons/hi'

import colors from 'assets/styles/partials/variables.scss'

import styles from './registerProponent.module.scss'

export type RegisterProponentProps = {
  onClick?: () => void
}

export const RegisterProponent: React.FC<RegisterProponentProps> = ({
  onClick
}) => {
  return (
    <button type='button' className={styles.container} onClick={onClick}>
      <div className={styles.icon}>
        <HiPlus size={35} color={colors['primary-color']} />
      </div>
      <h1>Cadastrar novo proponente</h1>
    </button>
  )
}
