import React from 'react'

import { Button, CurrencyInput } from 'components'

import styles from './DiscountCouponSection.module.scss'
export const DiscountCouponSection: React.FC = () => {
  return (
    <section>
      <h2>Cupom de desconto</h2>
      <div className={styles.content}>
        <CurrencyInput
          label='Código do cupom'
          value={2}
          size='xmedium'
          className={styles.whiteInput}
        />
        <Button className={styles.button} label='Aplicar cupom' />
      </div>
    </section>
  )
}
