import faker from '@faker-js/faker'

import { mockProponent } from '_mocks'
import { freeze } from 'utils/freeze/freeze'

import {
  CreateProponent,
  DeleteProponent,
  ListProponents,
  UpdateProponent,
  GetProponentData
} from './types'

const storage = [mockProponent(), mockProponent(), mockProponent()]

export const listProponents: ListProponents = async () => {
  await freeze()
  return storage
}

export const getProponentData: GetProponentData = async ({ id }) => {
  await freeze()
  const findedProponentIndex = storage.findIndex(p => p.id === id)
  if (findedProponentIndex < 0) throw new Error('ProponentNotFound')
  return storage[findedProponentIndex]
}

export const createProponent: CreateProponent = async params => {
  await freeze()
  const completeProponent = { ...params, id: faker.datatype.uuid() }
  storage.push(completeProponent)
  return completeProponent
}

export const updateProponent: UpdateProponent = async ({ id, proponent }) => {
  await freeze()
  const findedProponentIndex = storage.findIndex(p => p.id === id)
  if (findedProponentIndex < 0) throw new Error('ProponentNotFound')
  const completeProponent = { ...storage[findedProponentIndex], ...proponent }
  storage[findedProponentIndex] = completeProponent
  return completeProponent
}

export const deleteProponent: DeleteProponent = async ({ id }) => {
  await freeze()
  const findedProponentIndex = storage.findIndex(p => p.id === id)
  if (findedProponentIndex < 0) throw new Error('ProponentNotFound')
  storage.splice(findedProponentIndex, 1)
}
