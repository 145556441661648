import React from 'react'

import { PropertyCard, WelcomeModal } from 'components'
import { useProposal } from 'store/hooks'
import { BaseLayout } from 'template'

import { DocumentUploadContainer } from './containers'
import styles from './FilesUpload.module.scss'
import { useFiles } from './hooks/useFiles'

export const FilesUploadPage: React.FC = () => {
  const { proposal } = useProposal()

  const { isWelcomeModalOpen, setIsWelcomeModalOpen } = useFiles()

  return (
    <BaseLayout showLinkToDashboard>
      <WelcomeModal
        isOpen={isWelcomeModalOpen}
        closeModal={() => setIsWelcomeModalOpen(false)}
      />
      <div className={styles.container}>
        <DocumentUploadContainer />

        <PropertyCard
          property={proposal.property}
          className={styles.property}
        />
      </div>
    </BaseLayout>
  )
}
