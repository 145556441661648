import {
  isTablet,
  isAndroid,
  isWinPhone,
  isMacOs,
  browserVersion,
  browserName,
  isIOS,
  isSafari,
  isMobileSafari
} from 'react-device-detect'
import * as dt from 'react-device-detect'

export const isMobile = (): boolean => dt.isMobile

export {
  isTablet,
  isAndroid,
  isWinPhone,
  isMacOs,
  browserVersion,
  browserName,
  isIOS,
  isSafari,
  isMobileSafari
}
