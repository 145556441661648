import React from 'react'

import { Form } from 'components'
import {
  genderOptions,
  civilStatusOptions,
  maritalPropertySystemsOptions,
  ufOptions
} from 'utils/selectOptions'
import {
  isValidFullName,
  isValidCpf,
  isValidRg,
  isValidBirthdayDate,
  isValidPhone,
  isValidCnpj,
  isValidEmail,
  isValidCep,
  validationRules
} from 'utils/validators'

import styles from './PersonForm.module.scss'

export type PersonFormProps = {
  title?: string
  fields: any
  civilStateHidden: boolean
  handleChange: (field: string) => (value: string) => void
  isEdit: boolean
}

export const PersonForm: React.FC<PersonFormProps> = ({
  fields,
  handleChange,
  civilStateHidden,
  isEdit
}) => {
  const personTypeFields = [
    {
      type: 'radio',
      label: '',
      options: [
        {
          name: 'person_type',
          option: 'Pessoa física',
          checked: fields.personType === 'pf',
          value: 'pf',
          onChange: handleChange('personType')
        },
        {
          name: 'person_type',
          option: 'Pessoa jurídica',
          checked: fields.personType === 'pj',
          value: 'pj',
          onChange: handleChange('personType')
        }
      ]
    }
  ]

  const personalDatafields = [
    {
      label: 'Nome completo',
      type: 'text',
      value: fields.name,
      onChange: handleChange('name'),
      isValid: isValidFullName,
      required: true,
      capitalize: true,
      size: 'large',
      rules: validationRules.name
    },
    {
      label: 'CPF',
      type: 'text',
      value: fields.cpf,
      onChange: handleChange('cpf'),
      isValid: isValidCpf,
      mask: '***.***.***-**',
      required: true,
      size: 'small',
      rules: validationRules.cpf
    },
    {
      label: 'RG',
      type: 'text',
      value: fields.rg,
      onChange: handleChange('rg'),
      isValid: isValidRg,
      mask: '**.***.***-*',
      required: true,
      size: 'small',
      rules: validationRules.rg
    },
    {
      label: 'Emissor',
      type: 'text',
      value: fields.emitter,
      onChange: handleChange('emitter'),
      required: true,
      size: 'xsmall',
      rules: validationRules.emitter
    },
    {
      label: 'Data de nascimento',
      type: 'text',
      value: fields.birthday_date,
      onChange: handleChange('birthday_date'),
      isValid: isValidBirthdayDate,
      required: true,
      size: 'xsmall',
      rules: validationRules.birthDate,
      mask: '**/**/****'
    },
    {
      label: 'Profissão',
      type: 'text',
      value: fields.occupation,
      onChange: handleChange('occupation'),
      required: true,
      size: 'small'
    },
    {
      label: 'Nacionalidade',
      type: 'text',
      value: fields.nationality,
      onChange: handleChange('nationality'),
      required: true,
      size: 'small'
    },
    {
      label: 'Naturalidade',
      type: 'text',
      value: fields.naturalness,
      onChange: handleChange('naturalness'),
      required: true,
      size: 'small'
    },
    {
      type: 'select',
      label: 'Sexo',
      selected: fields.gender,
      onChange: handleChange('gender'),
      options: genderOptions,
      required: true,
      size: 'small'
    }
  ]

  const companyfields = [
    {
      label: 'CNPJ',
      type: 'text',
      value: fields.cnpj,
      onChange: handleChange('cnpj'),
      isValid: isValidCnpj,
      required: true,
      size: 'small',
      mask: '**.***.***/****-**'
    },
    {
      label: 'Razão social',
      type: 'text',
      value: fields.company_name,
      onChange: handleChange('company_name'),
      required: true,
      capitalize: true,
      size: 'large'
    },
    {
      label: 'Data de contribuição da empresa',
      type: 'text',
      value: fields.contribution_date,
      onChange: handleChange('contribution_date'),
      required: true,
      size: 'medium',
      mask: '**/**/****'
    },
    {
      label: 'Principal atividade',
      type: 'text',
      value: fields.activity,
      onChange: handleChange('activity'),
      required: true,
      size: 'medium'
    }
  ]

  const contactFields = [
    {
      label: 'Telefone',
      type: 'tel',
      value: fields.telephone,
      onChange: handleChange('telephone'),
      isValid: isValidPhone,
      mask: '(**) ****-****',
      required: true,
      size: 'small'
    },

    {
      label: 'Whatsapp',
      type: 'tel',
      value: fields.whatsapp,
      onChange: handleChange('whatsapp'),
      isValid: isValidPhone,
      mask: '(**) *****-****',
      required: true,
      size: 'small'
    },

    {
      label: 'Email',
      type: 'email',
      value: fields.email,
      onChange: handleChange('email'),
      isValid: isValidEmail,
      required: true,
      size: 'small'
    }
  ]

  const addressFields = [
    {
      label: 'Rua/Avenida',
      type: 'text',
      value: fields.street,
      onChange: handleChange('street'),
      required: true,
      size: 'large'
    },

    {
      label: 'Número',
      type: 'number',
      value: fields.number,
      onChange: handleChange('number'),
      required: true,
      size: 'xsmall'
    },

    {
      label: 'Complemento',
      type: 'text',
      value: fields.complement,
      onChange: handleChange('complement'),
      size: 'xsmall'
    },

    {
      label: 'Bairro',
      type: 'text',
      value: fields.neighborhood,
      onChange: handleChange('neighborhood'),
      required: true,
      size: 'small'
    },

    {
      label: 'Cidade',
      type: 'text',
      value: fields.city,
      onChange: handleChange('city'),
      required: true,
      size: 'small'
    },

    {
      label: 'UF',
      type: 'select',
      selected: fields.uf,
      onChange: handleChange('uf'),
      required: true,
      options: ufOptions,
      size: 'xsmall'
    },

    {
      label: 'CEP',
      type: 'text',
      value: fields.postal_code,
      onChange: handleChange('postal_code'),
      mask: '*****-***',
      isValid: isValidCep,
      required: true,
      size: 'xsmall'
    }
  ]

  const civilStateFields = [
    {
      type: 'select',
      label: 'Estado civil',
      selected: fields.civil_status,
      onChange: handleChange('civil_status'),
      options: civilStatusOptions,
      size: 'medium'
    },
    {
      type: 'select',
      label: 'Regime',
      selected: fields.marital_property_systems,
      onChange: handleChange('marital_property_systems'),
      options: maritalPropertySystemsOptions,
      size: 'medium',
      dontShow: fields.civil_status !== 'married',
      hidden: civilStateHidden
    }
  ]

  const isPhysicalPerson = fields.personType === 'pf'
  const title = isEdit ? 'Editar proponente' : 'Novo proponente'

  return (
    <div className={styles.container}>
      <h1>{title}</h1>

      <div className={styles.personType}>
        <h2>Tipo de pessoa</h2>
        <p>Selecione uma das opções abaixo</p>
        <Form fields={personTypeFields} />
      </div>

      <div>
        <h2>{isPhysicalPerson ? 'Dados do proponente' : 'Dados da empresa'}</h2>
        <Form fields={isPhysicalPerson ? personalDatafields : companyfields} />
        <Form title='Contato' fields={contactFields} />
        <Form title='Endereço' fields={addressFields} />
        {isPhysicalPerson && (
          <Form title='Estado civil' fields={civilStateFields} />
        )}
      </div>
    </div>
  )
}
