import React from 'react'

export type XIconProps = {
  width?: string
  height?: string
  color?: string
  onClick?: () => void
}

export const XIcon: React.FC<XIconProps> = ({
  width = 64,
  height = 64,
  color = 'white',
  onClick
}) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='32' cy='32' r='32' fill='black' fillOpacity='0.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.7882 25.2118C39.4131 25.8366 39.4131 26.8497 38.7882 27.4746L34.2627 32L38.7882 36.5255C39.4131 37.1504 39.4131 38.1634 38.7882 38.7883C38.1634 39.4131 37.1503 39.4131 36.5255 38.7883L32 34.2628L27.4745 38.7883C26.8497 39.4131 25.8366 39.4131 25.2118 38.7883C24.5869 38.1634 24.5869 37.1504 25.2118 36.5255L29.7373 32L25.2118 27.4746C24.5869 26.8497 24.5869 25.8366 25.2118 25.2118C25.8366 24.587 26.8497 24.587 27.4745 25.2118L32 29.7373L36.5255 25.2118C37.1503 24.587 38.1634 24.587 38.7882 25.2118Z'
        fill='#D8D8D8'
      />
      <mask
        id='mask0_2011_38547'
        // style='mask-type:alpha'
        maskUnits='userSpaceOnUse'
        x='24'
        y='24'
        width='16'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M38.7882 25.2118C39.4131 25.8366 39.4131 26.8497 38.7882 27.4746L34.2627 32L38.7882 36.5255C39.4131 37.1504 39.4131 38.1634 38.7882 38.7883C38.1634 39.4131 37.1503 39.4131 36.5255 38.7883L32 34.2628L27.4745 38.7883C26.8497 39.4131 25.8366 39.4131 25.2118 38.7883C24.5869 38.1634 24.5869 37.1504 25.2118 36.5255L29.7373 32L25.2118 27.4746C24.5869 26.8497 24.5869 25.8366 25.2118 25.2118C25.8366 24.587 26.8497 24.587 27.4745 25.2118L32 29.7373L36.5255 25.2118C37.1503 24.587 38.1634 24.587 38.7882 25.2118Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_2011_38547)'>
        <rect x='16' y='16' width='32' height='32' fill={color} />
      </g>
    </svg>
  )
}
