import React from 'react'

import { Toggle } from 'components'

import styles from './Notifications.module.scss'

type OptionType = {
  checked: boolean
  disabled: boolean
  id: string
  value: string
}

type NotificationType = {
  options: OptionType[]
  handleOption: (id: string) => void
}

export const Notifications: React.FC<NotificationType> = ({
  options,
  handleOption
}) => {
  return (
    <div className={styles.toggleSection}>
      {options.map((option: OptionType) => (
        <Toggle
          key={option.id}
          id={option.id}
          option={'Receber notificações da proposta via '}
          optionStrong={option.value}
          checked={option.checked}
          disabled={option.disabled}
          onChange={() => handleOption(option.id)}
        />
      ))}
    </div>
  )
}
