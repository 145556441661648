import React from 'react'

import { RiArrowDropDownLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import dashboardIcon from 'assets/svg/dashboardIcon.svg'
import logo from 'assets/svg/logoPagImovel.svg'
import profileIcon from 'assets/svg/profileIcon.svg'
import { Routes } from 'router/routes'
import { isMobile } from 'utils/deviceDetector'

import styles from './Header.module.scss'

export type HeaderProps = {
  showLinkToDashboard?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  showLinkToDashboard = true,
  children
}) => {
  const [openProfile, setOpenProfile] = React.useState(false)

  return (
    <header aria-label='header principal' className={styles.header}>
      {children}
      {showLinkToDashboard && isMobile() && (
        <Link to={Routes.PROPOSALS()} className={styles.listItem}>
          <img src={dashboardIcon} alt='Ícone de casa indicando o dashboard' />
        </Link>
      )}
      <div className={styles.logoContainer}>
        <Link to='/'>
          <img src={logo} alt='Logo PagImovel' />
        </Link>
      </div>
      <nav className={styles.navbar}>
        <ul>
          {showLinkToDashboard && !isMobile() && (
            <Link to={Routes.PROPOSALS()} className={styles.listItem}>
              <img
                src={dashboardIcon}
                alt='Ícone de casa indicando o dashboard'
                className={styles.dashboardIcon}
              />
              Ir para o dashboard
            </Link>
          )}
          <li>
            <button
              onClick={() => {
                setOpenProfile(!openProfile)
              }}
              className={styles.listItem}
            >
              <img src={profileIcon} alt='Ícone de pessoa indicando o perfil' />
              {!isMobile() && (
                <>
                  <span className={styles.profileLabel}>Meu perfil</span>
                  <RiArrowDropDownLine color='#444444' size={16} />
                </>
              )}
            </button>
            {openProfile && (
              <div className={styles.profile}>
                <span>
                  <ul>
                    <li>Opcao 1</li>
                    <li>Opcao 2</li>
                    <li>Opcao 3</li>
                  </ul>
                </span>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </header>
  )
}
