import React from 'react'

import { GenericDocument } from '_types'
import { DocumentCard, AddDocument } from 'components'
import useStore from 'store'
import { updateComplementaryDocuments } from 'store/proposal/actions'

import styles from './index.module.scss'
type ComplementaryStepProps = {
  complementaryDocuments: GenericDocument
  idProponent: string
}

export const ComplementaryStep: React.FC<ComplementaryStepProps> = ({
  complementaryDocuments,
  idProponent
}) => {
  const { dispatch } = useStore()

  const addDocument = (subtitle: string) => {
    const newDocument = {
      subtitle,
      path: '',
      name: ''
    }
    const newDocuments = [...complementaryDocuments.files, newDocument]

    dispatch(updateComplementaryDocuments(idProponent, newDocuments))
  }
  return (
    <div className={styles.container}>
      <DocumentCard
        title={complementaryDocuments.title}
        status={complementaryDocuments.status}
        files={complementaryDocuments.files}
        onChangeFiles={files =>
          dispatch(updateComplementaryDocuments(idProponent, files))
        }
      >
        <AddDocument onClick={addDocument} />
      </DocumentCard>
    </div>
  )
}
