export const NEW_RESOURCE_KEY = 'cadastrar'

export const Routes = {
  PROPOSALS: (id?: string): string =>
    id ? `/proposta-detalhe/${id}` : '/propostas',
  PROPOSAL: (id: string, step?: number): string =>
    step ? `/proposta/${id}?step=${step}` : `/proposta/${id}`,

  PROPONENTS: (): string => '/proponentes',
  PROFILE: (): string => '/perfil',
  FAQ: (): string => '/faq',
  FILES_TO_UPLOAD: (): string => '/'
}
