import React from 'react'

import secureIcon from 'assets/svg/secure.svg'
import { Modal, Button, Tutorial } from 'components'

import styles from './DocValidationSuccessModal.module.scss'

export type DocValidationSuccessModalProps = {
  isOpen: boolean
  closeModal: () => void
  onClick?: () => void
  onClickViewTutorial?: () => void
}

export const DocValidationSuccessModal: React.FC<DocValidationSuccessModalProps> = ({
  isOpen,
  closeModal,
  onClick = () => null,
  onClickViewTutorial = () => null
}) => {
  const [viewTutorial, setViewTutorial] = React.useState(false)

  const handleTutorial = () => {
    closeModal()
    setViewTutorial(!viewTutorial)
  }

  const handleClick = () => {
    closeModal()
    onClick()
  }

  const handleClickViewTutorial = () => {
    setViewTutorial(!viewTutorial)
    onClickViewTutorial()
  }

  return (
    <Modal
      typeModal='small'
      isOpen={isOpen}
      closeModal={closeModal}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <header>
          <img src={secureIcon} alt='Icone sucesso na validação' />
          <h1 className={styles.title}>Documento validado com sucesso!</h1>
        </header>
        <p className={styles.text}>
          Agora você pode usar a plataforma Pagimovel de forma segura.
        </p>
        <footer>
          <Button
            className={styles.btnTutorial}
            label='Ver tutorial'
            typeButton='outline'
            onClick={handleClickViewTutorial}
          />
          <Button
            className={styles.btnContinue}
            label='Começar a usar'
            onClick={handleClick}
          />
        </footer>
      </div>
      <Tutorial
        isVisible={viewTutorial}
        hideTutorial={() => handleTutorial()}
      />
    </Modal>
  )
}
