import { UserModel } from '_types'

export type State = UserModel

export type StatesMessages = 'promotionsMessages' | 'informationsMessages'
export type StateFormFields = 'name' | 'email' | 'phone' | 'password'
export type InnerFieldMessages = 'checked' | 'disabled'

export enum Types {
  CHANGE_TEXT = 'CHANGE_TEXT',
  UPDATE_USER = 'UPDATE_USER',
  CHANGE_NOTIFICATION = 'CHANGE_NOTIFICATION',
  CHANGE_FORM = 'CHANGE_FORM'
}

export type Payload = {
  [Types.UPDATE_USER]: {
    user: Partial<UserModel>
  }
  [Types.CHANGE_NOTIFICATION]: {
    field: StatesMessages
    innerField: InnerFieldMessages
  }
  [Types.CHANGE_FORM]: {
    field: StateFormFields
    value: string
  }
}

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>]
