import React, { useRef, useEffect, RefObject } from 'react'

import PropTypes from 'prop-types'

function useOutsideAlerter(ref: RefObject<HTMLDivElement>, action: () => void) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      // alert('You clicked outside of me!')
      action()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

type OutsideAlerterProps = {
  children: React.ReactNode
  action: () => void
  className: string
}
/**
 * Component that alerts if you click outside of it
 */
export function OutsideAlerter({
  children,
  action,
  className
}: OutsideAlerterProps): JSX.Element {
  const wrapperRef = useRef<HTMLDivElement>(null)
  useOutsideAlerter(wrapperRef, action)

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  )
}

OutsideAlerter.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  className: PropTypes.string
}

OutsideAlerter.defaultProps = {
  className: ''
}
