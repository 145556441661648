import faker from '@faker-js/faker'

import { UserModel } from '_types'

import { emptyProponent, mockProponent } from './proponent'

export const emptyUser = (): UserModel => ({
  proponent: emptyProponent(),
  informationsMessages: {
    sms: { checked: false, disabled: false },
    email: { checked: false, disabled: false },
    whatsapp: { checked: false, disabled: false },
    pushNotification: { checked: false, disabled: false }
  },
  promotionsMessages: {
    sms: { checked: false, disabled: false },
    email: { checked: false, disabled: false },
    whatsapp: { checked: false, disabled: false },
    pushNotification: { checked: false, disabled: false }
  }
})

export const mockUser = (): UserModel => ({
  ...emptyUser(),
  proponent: {
    ...mockProponent(),
    name: faker.name.findName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber()
  }
})
