import { emptyUser } from '_mocks'

import * as UserTypes from './types'

export const InitialState: UserTypes.State = emptyUser()

export function reducer(
  state = InitialState,
  action: UserTypes.Actions
): UserTypes.State {
  switch (action.type) {
    case UserTypes.Types.UPDATE_USER:
      return { ...state, ...action.payload.user }
    case UserTypes.Types.CHANGE_NOTIFICATION:
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          [action.payload.innerField]: {
            ...state[action.payload.field][action.payload.innerField],
            checked: !state[action.payload.field][action.payload.innerField]
              .checked
          }
        }
      }
    default:
      return state
  }
}
