import React from 'react'

import { listBanners } from 'assets/images/vendors-banners'
import { useProposal } from 'store/hooks'

import styles from './BankBanner.module.scss'

export const BankBanner: React.FC = () => {
  const { proposal } = useProposal()

  if (!proposal.property.vendorId) return null

  return (
    <>
      <figure className={styles.banner}>
        <img
          src={listBanners[proposal.property.vendorId].desktop}
          alt={`Banner de ofertas exclusivas de ${proposal.property.vendorName}`}
          className={styles.bannerImage}
        />
        <img
          src={listBanners[proposal.property.vendorId].mobile}
          alt={`Banner de ofertas exclusivas de ${proposal.property.vendorName}`}
          className={styles.bannerImageMobile}
        />
      </figure>
    </>
  )
}
