import faker from '@faker-js/faker'

import { mockProposal } from '_mocks'
import { ProposalModel } from '_types'
import { freeze } from 'utils/freeze/freeze'

import {
  CancelProposal,
  GetProposalData,
  ListProposal,
  SaveProposalDraft,
  SendProposalToAnalysis
} from './types'

const storage = [mockProposal(), mockProposal(), mockProposal()]

export const listProposal: ListProposal = async () => {
  await freeze()
  return storage
}

export const getProposalData: GetProposalData = async ({ id }) => {
  await freeze()
  const findedProposal = storage.find(p => p.id === id)
  if (!findedProposal) throw new Error('ProposalNotFound')
  return findedProposal
}

export const saveProposalDraft: SaveProposalDraft = async (
  params: ProposalModel
) => {
  await freeze()
  const completeProposal = { ...params, id: faker.datatype.uuid() }
  storage.push(completeProposal)
  return completeProposal
}

export const sendProposalToAnalysis: SendProposalToAnalysis = async (
  params: ProposalModel
) => {
  await freeze()
  const completeProposal = { ...params, id: faker.datatype.uuid() }
  storage.push(completeProposal)
  return completeProposal
}

export const cancelProposal: CancelProposal = async () => {
  await freeze()
}
