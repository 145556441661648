import React from 'react'

import classnames from 'classnames'
import ScrollContainer from 'react-indiana-drag-scroll'
import ReactTooltip from 'react-tooltip'

import { PropertyModel } from '_types'
import arrowDownIcon from 'assets/svg/arrowDown.svg'
import starNewPropertyIcon from 'assets/svg/starNewProperty.svg'
import {
  Image,
  AprraisedValue,
  SaleValue,
  Tag,
  Icon,
  Text,
  PicsCarousel
} from 'components'
import { getBankLogo } from 'utils/getBankLogo'
import {
  imagesOptions,
  getPropertyCategoryImage
} from 'utils/getPropertyCategoryImage'

import styles from './PropertyCard.module.scss'

export type PropertyCardProps = {
  property: PropertyModel
  className?: string
}

export const PropertyCard: React.FC<PropertyCardProps> = ({
  property,
  className
}) => {
  const width = window.innerWidth

  const {
    name,
    photo,
    photoArray,
    longPhoto,
    appraisedValue,
    saleLabel,
    saleValue,
    tags,
    soldStatus,
    bank,
    category,
    features,
    desagio
  } = property

  const isSold = soldStatus === 'vendido'
  const hasDesagio = Number(desagio) >= 1
  const isNewProperty = tags.map(tag => /Novidade/i.test(tag))

  const ImageHeader: React.FC<{ pic: string }> = ({ pic = '' }) => {
    return (
      <figure className={`${styles.image} ${isSold ? styles.sold : ''}`}>
        <Image
          alt={name}
          title={name}
          source={width < 1024 ? longPhoto : pic}
          noPhoto={
            category &&
            getPropertyCategoryImage(
              width < 1024 ? (`${category}Large` as imagesOptions) : category
            )
          }
        />

        {isSold && (
          <div className={styles.soldTag}>
            <span>VENDIDO</span>
          </div>
        )}

        {hasDesagio && (
          <div className={styles.desagio}>
            <img
              loading='lazy'
              src={arrowDownIcon}
              width='9px'
              alt='desagio arrow'
            />
            <span>{`${desagio}%`}</span>
          </div>
        )}

        {isNewProperty && !isSold && (
          <figure className={styles.novidade}>
            <img
              loading='lazy'
              src={starNewPropertyIcon}
              alt='Estrela na cor verde da novidade'
              title='Estrela da novidade'
            />
            <span>Novidade</span>
          </figure>
        )}

        {bank && getBankLogo({ bank }) && (
          <img
            src={getBankLogo({ bank })}
            className={classnames(styles.bankLogo, {
              [styles.propertyIsSold]: isSold
            })}
            alt={`Logo do banco ${bank}`}
          />
        )}
      </figure>
    )
  }

  const Info = () => {
    return (
      <div className={styles.information}>
        <header>
          <h1>{name}</h1>
          <Icon type={'StoreRoom'} />
          <p data-tip={`${property.address} - ${property.description}`}>
            {property.address}
            {property.description}
          </p>

          <ReactTooltip
            backgroundColor='#FFFF'
            className={styles.tooltip}
            place='right'
            type='info'
            effect='solid'
          />
        </header>
      </div>
    )
  }

  const Features = () => {
    const featuresMap = {
      'Dormitório(s)': 'dorm',
      'Suíte(s)': 'suítes',
      'Vaga(s) na Garagem': 'vaga(s)'
    }

    return (
      <div className={styles.features}>
        {features.map(({ id, feature, amount, icon }) => {
          return (
            parseInt(amount) > 0 && (
              <>
                <div className={styles.feat} key={id}>
                  <Icon type={icon} color={'blackLight'} />
                  <p>
                    {amount} {feature && featuresMap[feature]}
                  </p>
                </div>
              </>
            )
          )
        })}
      </div>
    )
  }
  const Values = () => {
    return (
      <div className={styles.values}>
        {Number(appraisedValue) > Number(saleValue) ? (
          <span className={styles.prices}>
            <AprraisedValue
              value={appraisedValue}
              strike={appraisedValue !== saleValue}
            />
            <span className={styles.discount}>
              <SaleValue value={saleValue} asterisk={false} />
            </span>
          </span>
        ) : (
          <span>
            <Text>{saleLabel}</Text>
            <SaleValue value={saleValue} asterisk={false} />
          </span>
        )}
      </div>
    )
  }

  const Tags = () => {
    return (
      <div className={styles.tags}>
        <ScrollContainer className={styles.scrollContainer} vertical={false}>
          {tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </ScrollContainer>
      </div>
    )
  }

  return (
    <article className={`${styles.card} ${className}`}>
      {Array.isArray(photoArray) && photoArray.length ? (
        <PicsCarousel>
          {photoArray.map(pic => (
            <ImageHeader key={pic} pic={pic} />
          ))}
        </PicsCarousel>
      ) : (
        <ImageHeader pic={photo} />
      )}
      <Tags />
      <Values />
      <Info />
      <Features />
    </article>
  )
}
