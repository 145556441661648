import React from 'react'

import { useHistory } from 'react-router-dom'

import {
  Button,
  PurchaseInformation,
  Loader,
  ProposalTimeline,
  PropertyCard
} from 'components'
import { Routes } from 'router/routes'
import { BaseLayoutWithSideMenu } from 'template'

import { Notifications } from './containers'
import styles from './ProposalDetailsPage.module.scss'
import { useProposalDetails } from './useProposalDetails'

export const ProposalDetailsPage: React.FC = () => {
  const { proposal, loading, options, handleOption } = useProposalDetails()
  const history = useHistory()

  if (loading || !proposal) {
    return (
      <BaseLayoutWithSideMenu>
        <Loader />
      </BaseLayoutWithSideMenu>
    )
  }

  return (
    <BaseLayoutWithSideMenu>
      <div className={styles.page}>
        <p className={styles.link}>
          <span onClick={() => history.push(Routes.PROPOSALS())}>
            Propostas &gt;
          </span>
          {` ${proposal.property.name}`}
        </p>
        <div className={styles.container}>
          <section>
            <PurchaseInformation
              numberProposal={proposal.numberProposal}
              idProperty={proposal.property.id}
              position={proposal.position}
            />

            <h2>Linha do tempo</h2>
            <div className={styles.background}>
              <ProposalTimeline
                data={proposal.dataTime || []}
                onClick={id => console.log(id)}
              />
            </div>

            <h2>Notificações</h2>
            <div className={styles.background}>
              <Notifications
                options={options || []}
                handleOption={id => handleOption(id)}
              />
            </div>

            <div className={styles.actions}>
              <Button typeButton='outline' label='Fale conosco' />
              <Button typeButton='outline' label='Cancelar proposta' />
              <Button label='Refazer proposta' />
            </div>
          </section>
          <PropertyCard property={proposal.property} />
        </div>
      </div>
    </BaseLayoutWithSideMenu>
  )
}
