import React from 'react'

import 'react-multi-carousel/lib/styles.css'
import Carousel from 'react-multi-carousel'

import styles from './PicsCarousel.module.scss'
import responsive from './responsive'

const PicsCarousel: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <Carousel
        additionalTransfrom={0}
        responsive={responsive}
        swipeable
        arrows
        showDots
        infinite
        slidesToSlide={1}
        renderDotsOutside={false}
        containerClass={styles.container}
        autoPlaySpeed={4000}
      >
        {children}
      </Carousel>
    </div>
  )
}

export default PicsCarousel
