import React from 'react'

import { HiPlus } from 'react-icons/hi'

import colors from 'assets/styles/partials/variables.scss'

import styles from './AddProponent.module.scss'

export type AddProponentProps = {
  onClick?: () => void
}

export const AddProponent: React.FC<AddProponentProps> = ({ onClick }) => {
  return (
    <button type='button' className={styles.container} onClick={onClick}>
      <div className={styles.icon}>
        <HiPlus size={35} color={colors['primary-color']} />
      </div>
      <h1>Adicionar novo proponente</h1>
    </button>
  )
}
