import React from 'react'

import classNames from 'classnames'

import { Text } from 'components'

import styles from './AprraisedValue.module.scss'

export type AprraisedValueProps = {
  value: number
  type?: 'row' | 'column'
  strike?: boolean
}
export const AprraisedValue: React.FC<AprraisedValueProps> = ({
  value,
  type = 'column',
  strike = false
}) => {
  if (!value) {
    return null
  }

  return (
    <div className={styles[type]}>
      <Text
        color='textColor'
        className={classNames(styles.value, { [styles.strike]: strike })}
        fontSize={type === 'column' ? 'medium' : 'regular'}
      >
        {`R$ ${value.toLocaleString('pt-br')}`}
      </Text>
    </div>
  )
}
