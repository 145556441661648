import bbLogoAzul from 'assets/images/banks-logo/banco-do-brasil-azul.webp'
import bbLogo from 'assets/images/banks-logo/banco-do-brasil.webp'
import btgAzul from 'assets/images/banks-logo/btg-azul.webp'
import btg from 'assets/images/banks-logo/btg.webp'
import caixaAzul from 'assets/images/banks-logo/caixa-azul.webp'
import caixa from 'assets/images/banks-logo/caixa.webp'
import embraconAzul from 'assets/images/banks-logo/embracon-azul.webp'
import embracon from 'assets/images/banks-logo/embracon.webp'
import santanderAzul from 'assets/images/banks-logo/santander-azul.webp'
import santander from 'assets/images/banks-logo/santander.webp'

export type banks =
  | '77628f11-00ec-d3c1-a5fb-5d8140ea843b'
  | 'f0bad832-8ec9-aba2-b72d-5909efb6e044'
  | '5fdd6abd-c5c1-46ab-5dfe-5f3ec29d3889'
  | '9e93cf66-2d28-98de-c4d7-5be9aa4caf9b'
  | '4dd4393a-2318-4576-8d8f-56b25c5c0e3a'
  | ''
export type colors = 'white' | 'blue'

export type BankLogoProps = {
  bank: banks
  color?: colors
}

// @TODO confirmar nomes dos bancos q vem da API
export const getBankLogo = ({
  bank,
  color = 'white'
}: BankLogoProps): string => {
  const logos = {
    '77628f11-00ec-d3c1-a5fb-5d8140ea843b': { white: bbLogo, blue: bbLogoAzul },
    'f0bad832-8ec9-aba2-b72d-5909efb6e044': { white: caixa, blue: caixaAzul },
    '5fdd6abd-c5c1-46ab-5dfe-5f3ec29d3889': {
      white: btg,
      blue: btgAzul
    },
    '9e93cf66-2d28-98de-c4d7-5be9aa4caf9b': {
      white: embracon,
      blue: embraconAzul
    },
    '4dd4393a-2318-4576-8d8f-56b25c5c0e3a': {
      white: santander,
      blue: santanderAzul
    }
  }

  return bank !== '' && logos[bank] ? logos[bank][color] : ''
}
