import React from 'react'

import classnames from 'classnames'
import { IoIosClose } from 'react-icons/io'

import styles from './Modal.module.scss'

export type ModalProps = {
  isOpen: boolean
  closeModal: () => void
  closeButton?: boolean
  typeModal?: 'normal' | 'fullscreen' | 'small' | 'xsmall'
  className?: string
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  closeButton = false,
  typeModal = 'normal',
  children,
  className,
  ...props
}) => {
  const preventBubbling = (e: React.MouseEvent) => e.stopPropagation()
  return (
    <>
      {isOpen && (
        <div onClick={closeModal} className={styles.overlay}>
          <div
            onClick={preventBubbling}
            className={classnames(styles[typeModal], className)}
            {...props}
          >
            <div>{children}</div>
            {closeButton && (
              <div className={styles.closeButton}>
                <IoIosClose
                  size={32}
                  onClick={closeModal}
                  color='black'
                  data-testid='closeIcon'
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
