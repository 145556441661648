import React from 'react'

import { ProponentModel } from '_types'
import { BankBanner, BottomNavigation, Button, Loader } from 'components'
import { useStepValidations } from 'hooks/useStepValidation'
import { useToggle } from 'hooks/useToogle'
import { BaseLayout } from 'template'

import {
  StepContainer,
  ProposalTutorialContainer,
  ProponentContainer,
  ProposalContainer,
  SignatureContainer,
  DocumentationContainer
} from './containers'
import styles from './Proposal.module.scss'
import { useProposalPage } from './useProposalPage'

type StepsMap = { [key: number]: React.ReactElement }

export function ProposalPage(): JSX.Element {
  const proponentFormToggle = useToggle<ProponentModel>()

  const {
    proposalStep,
    tutorialIsOpen,
    onTutorialFinish,
    handleChangeStep,
    loading
  } = useProposalPage()

  const { canNavigateToStep } = useStepValidations({
    currentStep: proposalStep
  })

  const stepsMap: StepsMap = {
    1: <ProponentContainer proponentFormToggle={proponentFormToggle} />,
    2: <ProposalContainer />,
    3: <SignatureContainer />,
    4: <DocumentationContainer />
  }

  const mainButtonMap: { [key: number]: React.ReactElement } = {
    1: (
      <Button
        label='Preencher proposta'
        isDisable={!canNavigateToStep(2)}
        onClick={() => handleChangeStep(2)}
      />
    ),
    2: (
      <Button
        label='Enviar proposta'
        isDisable={!canNavigateToStep(3)}
        onClick={() => handleChangeStep(3)}
      />
    ),
    3: (
      <Button
        label='Enviar assinatura'
        isDisable={!canNavigateToStep(4)}
        onClick={() => handleChangeStep(4)}
      />
    ),
    4: (
      <Button
        label='Enviar para validação'
        isDisable={false}
        onClick={() => null}
      />
    )
  }

  if (tutorialIsOpen) {
    return <ProposalTutorialContainer onCloseTutorial={onTutorialFinish} />
  }

  if (loading) {
    return (
      <BaseLayout>
        <Loader />
      </BaseLayout>
    )
  }

  return (
    <BaseLayout>
      <div className={styles.container}>
        <BankBanner />
        <div className={styles.stepBar}>
          <StepContainer
            proponentFormToggle={proponentFormToggle.isOpen}
            handleChangeStep={handleChangeStep}
            proposalStep={proposalStep}
          />
        </div>
        <main className={styles.main}>{stepsMap[proposalStep]}</main>
      </div>

      <BottomNavigation mainButton={mainButtonMap[proposalStep]} />
    </BaseLayout>
  )
}
