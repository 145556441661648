import React, { useEffect, useState, useCallback } from 'react'

import classnames from 'classnames'
import CurrencyInputLib from 'react-currency-input-field'
import { IoIosClose, IoIosCheckmark } from 'react-icons/io'

import color from 'assets/styles/partials/variables.scss'
import { sizeStyle } from 'utils/sizeStyle'

import styles from './CurrencyInput.module.scss'

export type CurrencyInputProps = {
  value: number
  onChange?: (value: string) => void
  label?: string
  required?: boolean
  isValid?: (value: string) => boolean
  placeholder?: string
  onFocus?: () => void
  disabled?: boolean
  readOnly?: boolean
  size?: sizeStyle
  rules?: string[]
  touched?: boolean
  validateOnBlur?: boolean
  className?: string
  rows?: number
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  label = '',
  required,
  placeholder,
  onChange = () => null,
  isValid,
  size = 'max',
  rules = ['Campo inválido'],
  touched,
  className,
  ...props
}) => {
  const [valid, setValid] = useState<boolean | null>(null)

  const [internalTouch, setInternalTouch] = useState(touched)
  useEffect(() => setInternalTouch(touched), [touched])

  const hasError = !valid && valid !== null

  const validate = useCallback(
    (value = '') => {
      const isValidField = isValid ? isValid(value) : !!value

      if (internalTouch) {
        setValid(isValidField)
      }
    },
    [internalTouch, isValid]
  )

  const onChangeValue = (value: string | undefined) => {
    validate(value)
    onChange(value || '')
  }

  useEffect(() => {
    validate(value)
  }, [value, internalTouch, validate])

  const containerInputClasses = classnames({
    [styles.inputElement]: true,
    [className || '']: true,
    [styles.invalid]: !!hasError,
    [styles.valid]: !!valid
  })

  return (
    <div className={`${styles.inputContainer} ${styles[size]}`}>
      <label>
        <p>
          {label}
          {required && '*'}
        </p>
        <div className={containerInputClasses}>
          <CurrencyInputLib
            id='input-example'
            name='input-name'
            placeholder={placeholder}
            decimalsLimit={2}
            onValueChange={onChangeValue}
            onBlur={() => setInternalTouch(true)}
            prefix='R$ '
            {...props}
          />
        </div>
        {hasError && (
          <ul className={styles.errorMessage}>
            <p>
              *{' '}
              {rules.map(
                (rule, index) =>
                  `${rule}${index < rules.length - 1 ? ', ' : '.'}`
              )}
            </p>
          </ul>
        )}
      </label>

      {hasError && (
        <IoIosClose
          title='invalid icon'
          color={color.error}
          className={styles.icon}
          size={30}
        />
      )}
      {valid && (
        <IoIosCheckmark
          title='valid icon'
          color={color.success}
          className={styles.icon}
          size={30}
        />
      )}
    </div>
  )
}
