import React from 'react'

import { Button } from 'components'

import { ForwardedTutorialProps } from '../Tutorial'
import styles from './Navigation.module.scss'

export const Navigation: React.FC<ForwardedTutorialProps> = ({
  hideTutorial,
  isLastStep,
  handleNextStep,
  totalSteps,
  actualStep
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.dots}>
        {[...Array(totalSteps)].map((_, index) => (
          <div
            className={`${styles.dot} ${
              actualStep === index ? styles.active : ''
            }`}
            key={index}
          />
        ))}
      </div>
      <div className={styles.buttonWrapper}>
        {isLastStep ? (
          <Button label='Iniciar proposta' onClick={hideTutorial} />
        ) : (
          <Button
            label='Pular tutorial'
            onClick={hideTutorial}
            typeButton='outline'
          />
        )}

        {!isLastStep && <Button label='Continuar' onClick={handleNextStep} />}
      </div>
    </div>
  )
}
