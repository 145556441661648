import React from 'react'

import successIcon from 'assets/svg/sucesso.svg'
import { Modal, Button } from 'components'

import styles from './ProponentSuccessModal.module.scss'

export type ProponentSuccessModalProps = {
  isOpen: boolean
  closeModal: () => void
}

export const ProponentSuccessModal: React.FC<ProponentSuccessModalProps> = ({
  isOpen,
  closeModal
}) => {
  const continueProposal = () => {
    closeModal()
    // TODO
  }
  return (
    <Modal
      typeModal={'normal'}
      isOpen={isOpen}
      closeModal={closeModal}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <img src={successIcon} alt='Icone de sucesso' />
        <h1>Proponente criado com sucesso!</h1>
        <p>Edite as informações posteriormente, se for necessário.</p>
        <Button label='Continuar proposta' onClick={continueProposal} />
      </div>
    </Modal>
  )
}
