import React from 'react'

import styles from './RadioButton.module.scss'

export type RadioButtonProps = {
  checked: boolean
  value: string
  name: string
  option?: string
  className?: string
  type?: string
  onChange: (value: string | boolean) => void
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  option,
  checked,
  onChange,
  value,
  name,
  className = '',
  type
}) => {
  return (
    <label htmlFor={value} className={`${styles.radio} ${className}`}>
      <span className={styles.inputGroup}>
        <input
          id={value}
          name={name}
          type='radio'
          checked={checked}
          value={value}
          onClick={() =>
            type === 'toggle' ? onChange(!value) : onChange(value)
          }
        />
        <label className={styles.labelRadio} htmlFor={value} />
      </span>
      {option && <p>{option}</p>}
    </label>
  )
}
