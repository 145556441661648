import React from 'react'

import classNames from 'classnames'

import styles from './SaleValue.module.scss'
export type SaleValueProps = {
  value: number
  asterisk?: boolean
  className?: string
}

export const SaleValue: React.FC<SaleValueProps> = ({
  value,
  asterisk = false,
  className = ''
}) => {
  return (
    <strong className={classNames(styles.span, className)}>
      {value
        ? `R$ ${parseInt(value.toString(), 10).toLocaleString('pt-br')}`
        : 'Sob consulta'}
      {asterisk && value ? '*' : ''}
    </strong>
  )
}
