import React from 'react'

import { PropertyCard } from 'components'
import { useProposal } from 'store/hooks'

import styles from './PropertyContainer.module.scss'

export const PropertyContainer: React.FC = () => {
  const { proposal } = useProposal()
  return (
    <div className={styles.container}>
      <PropertyCard property={proposal.property} />
    </div>
  )
}
