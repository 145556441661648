import React from 'react'

export type ArrowProps = {
  width?: string
  height?: string
  color?: string
}

export const ArrowBottom: React.FC<ArrowProps> = ({
  width = 12,
  height = 7,
  color = '#444444'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.45947 6.35147C5.84999 6.74199 6.48316 6.74199 6.87368 6.35147L11.352 1.87312C11.7425 1.4826 11.7425 0.849435 11.352 0.458911C10.9615 0.0683865 10.3283 0.0683861 9.93781 0.45891L6.16657 4.23015L2.39534 0.458909C2.00482 0.068385 1.37165 0.0683849 0.981126 0.458909C0.590601 0.849433 0.590601 1.4826 0.981125 1.87312L5.45947 6.35147Z'
        fill={color}
      />
    </svg>
  )
}
