import React from 'react'

import classnames from 'classnames'
import { HiPlus } from 'react-icons/hi'

import colors from 'assets/styles/partials/variables.scss'

import styles from './AddButton.module.scss'

export type AddButtonProps = {
  label: string
  onClick?: () => void
  className?: string
}

export const AddButton: React.FC<AddButtonProps> = ({
  label,
  onClick,
  className
}) => {
  const buttonClasses = classnames({
    [styles.container]: true,
    [className || '']: !!className
  })

  return (
    <button type='button' className={buttonClasses} onClick={onClick}>
      <div className={styles.icon}>
        <HiPlus size={35} color={colors['primary-color']} />
      </div>
      <h1>{label}</h1>
    </button>
  )
}
