import React from 'react'

import classnames from 'classnames'
import { NavLink } from 'react-router-dom'

import { isMobile } from 'utils/deviceDetector'

import styles from './SideMenu.module.scss'

const options = [
  {
    name: 'Propostas',
    to: '/propostas'
  },
  {
    name: 'Proponentes salvos',
    to: '/proponentes'
  },
  {
    name: 'Meu perfil',
    to: '/perfil'
  },
  {
    name: 'Dúvidas',
    to: '/faq'
  }
]

export const SideMenu: React.FC = () => {
  const content = (
    <div
      className={classnames(styles.sideMenu, { [styles.mobile]: isMobile() })}
    >
      <nav>
        <ul>
          {options.map((element, id) => {
            return (
              <li key={id}>
                <NavLink to={element.to} activeClassName={styles.select} exact>
                  {element.name}
                </NavLink>
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
  return isMobile() ? (
    <div className={styles.overlay} onClick={e => e.stopPropagation()}>
      {content}
    </div>
  ) : (
    content
  )
}
