import React, { useState } from 'react'

import {
  Button,
  DocValidationSuccessModal,
  ValidatingDocModal,
  ProponentSuccessModal,
  DocValidationDisapprovedModal,
  DuplicatedProposalModal,
  RequiredDocsModal
} from 'components'

import styles from './Faq.module.scss'

export function FaqPage(): JSX.Element {
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [openValidatingModal, setOpenValidatingModal] = useState(false)
  const [openSuccesProponent, setOpenSuccessProponent] = useState(false)
  const [modalDisapproved, setModalDisapproved] = useState(false)
  const [modalDuplicated, setModalDuplicated] = useState(false)
  const [requiredDocs, setRequiredDocs] = useState(false)

  const proponentName = 'André Brandão'
  const proponentDocs = [
    { doc: 'Selfie com RG / CNH*', status: '' },
    { doc: 'Comprovante de residência*', status: '' },
    { doc: 'Certidão de nascimento*', status: 'validacao_automatica' },
    { doc: 'CPF*', status: '' },
    { doc: 'RG / CNH*', status: 'validacao_manual' }
  ]
  const spouseDocs = [
    { doc: 'Selfie com RG / CNH*', status: '' },
    { doc: 'Comprovante de residência*', status: '' },
    { doc: 'Certidão de nascimento*', status: '' },
    { doc: 'CPF*', status: 'validacao_manual' },
    { doc: 'RG / CNH*', status: '' }
  ]
  const complementaryDocs = [
    { doc: 'Documento X*', status: '' },
    { doc: 'Documento Y*', status: '' }
  ]
  return (
    <div className={styles.App}>
      <DocValidationSuccessModal
        isOpen={openSuccessModal}
        closeModal={() => setOpenSuccessModal(prev => !prev)}
      />
      <ValidatingDocModal
        isOpen={openValidatingModal}
        closeModal={() => setOpenValidatingModal(prev => !prev)}
      />
      <ProponentSuccessModal
        isOpen={openSuccesProponent}
        closeModal={() => setOpenSuccessProponent(prev => !prev)}
      />

      <DocValidationDisapprovedModal
        isOpen={modalDisapproved}
        closeModal={() => setModalDisapproved(prev => !prev)}
      />

      <DuplicatedProposalModal
        isOpen={modalDuplicated}
        closeModal={() => setModalDuplicated(prev => !prev)}
        onClick={() => null}
      />

      <RequiredDocsModal
        isOpen={requiredDocs}
        closeModal={() => setRequiredDocs(prev => !prev)}
        proponentName={proponentName}
        proponentDocs={proponentDocs}
        spouseDocs={spouseDocs}
        complementaryDocs={complementaryDocs}
      />
      <header className={styles['App-header']}>
        <Button
          label='Abrir Modal de Sucesso Validacao Doc'
          onClick={() => setOpenSuccessModal(prev => !prev)}
        />
        <Button
          label='Abrir Modal de Validacao de Documento (Spinner)'
          onClick={() => setOpenValidatingModal(prev => !prev)}
          typeButton='outline'
        />
        <Button
          label='Abrir Modal de Sucesso Proponente'
          onClick={() => setOpenSuccessProponent(prev => !prev)}
        />
        <Button
          label='Abrir Modal de Documento Reprovado'
          onClick={() => setModalDisapproved(prev => !prev)}
          typeButton='outline'
        />
        <Button
          label='Abrir Modal de Proposta Duplicada'
          onClick={() => setModalDuplicated(prev => !prev)}
        />
        <Button
          label='Abrir Modal de Documentos Requeridos'
          onClick={() => setRequiredDocs(prev => !prev)}
          typeButton='outline'
        />
        <p>Tela da Faq</p>
      </header>
    </div>
  )
}
