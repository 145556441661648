import React from 'react'

export type ArrowProps = {
  width?: string
  height?: string
  color?: string
}

export const ArrowTop: React.FC<ArrowProps> = ({
  width = 12,
  height = 7,
  color = '#444444'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.54053 0.647802C6.15001 0.257278 5.51684 0.257277 5.12632 0.647801L0.647975 5.12614C0.257451 5.51667 0.257451 6.14983 0.647975 6.54036C1.0385 6.93088 1.67166 6.93088 2.06219 6.54036L5.83342 2.76912L9.60466 6.54036C9.99518 6.93088 10.6283 6.93088 11.0189 6.54036C11.4094 6.14983 11.4094 5.51667 11.0189 5.12615L6.54053 0.647802Z'
        fill={color}
      />
    </svg>
  )
}
