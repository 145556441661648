import { useState, useEffect, useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { dataDetails } from '_mocks'
import { ProposalDetails } from '_types'

type OptionType = {
  checked: boolean
  disabled: boolean
  id: string
  value: string
}[]

export type UseProposalList = {
  proposal: ProposalDetails | undefined
  loading: boolean
  options: OptionType | undefined
  handleOption: (id: string) => void
}

export function useProposalDetails(): UseProposalList {
  const [proposal, setProposal] = useState<ProposalDetails>()
  const [options, setOptions] = useState<OptionType>([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      // Todo : fazer a request integrar com o mock
      const pathname = history.location.pathname.split('/')
      const numberProposal = pathname[2]

      const response: ProposalDetails = await dataDetails(numberProposal)
      setProposal(response)

      const auxOptions = response.notification.map(
        ({ key, label, disabled, value }) => ({
          checked: value,
          disabled: disabled,
          id: key,
          value: label
        })
      )
      setOptions(auxOptions)
    } catch (error) {
      toast.error('Erro na listagem de propostas')
    } finally {
      setLoading(false)
    }
  }, [history])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleOption = (id: string) => {
    // Todo request de setar no back as notificações

    const auxOptions = options.map(el => {
      if (el.id === id) return { ...el, checked: !el.checked }
      return el
    })
    setOptions(auxOptions)
  }

  return {
    proposal,
    options,
    loading,
    handleOption
  }
}
