import React, { useState } from 'react'

import { Exclamation, ArrowTop, ArrowBottom } from 'assets/svg'

import styles from './DocValidationDisapproved.module.scss'

export type Props = {
  title?: string
  reason?: string
}

export const DocValidationDisapproved: React.FC<Props> = ({
  title,
  reason
}) => {
  const [active, setActive] = useState<boolean>(false)
  return (
    <section className={styles.container}>
      <header className={styles.header} onClick={() => setActive(!active)}>
        <div>
          <Exclamation />
          <h1 className={styles.title}>{title}</h1>
        </div>
        {active ? <ArrowBottom /> : <ArrowTop />}
      </header>
      {active && <p>Motivo: {reason}</p>}
    </section>
  )
}
