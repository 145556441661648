/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'

import classNames from 'classnames'
import { useDropzone } from 'react-dropzone'

import { XIcon, SearchCircle } from 'assets/svg'
import uploadIcon from 'assets/svg/upload.svg'
import { DocValidationDisapproved } from 'components'

import styles from './Dropzone.module.scss'

export type DropzoneCardProps = {
  title: string
  messageError?: string | undefined
  onAddFiles?: <T extends File>(files: T[]) => void
  onRemoveFiles?: () => void
}

export const DropzoneCard: React.FC<DropzoneCardProps> = ({
  title,
  messageError,
  onAddFiles = () => null,
  onRemoveFiles = () => null
}) => {
  const [files, setFiles] = useState<any>([])
  const [active, setActive] = useState<boolean>(true)
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf',
    onDrop: acceptedFiles => {
      setActive(false)
      setFiles(
        acceptedFiles.map(<T extends File>(file: T) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
    }
  })

  const onRemoveImage = () => {
    setActive(false)
    setFiles([])
    onRemoveFiles()
  }

  useEffect(() => {
    files.forEach((file: any) => URL.revokeObjectURL(file.preview))
    onAddFiles(files)

    // eslint-disable-next-line
  }, [files])

  const thumbs = files.map(
    (file: { preview: string; name: string; type: string }) => (
      <div className={styles.thumb} key={file.name}>
        <div className={styles.thumbInner}>
          {file.type === 'application/pdf' ? (
            <iframe
              title={file.name}
              src={file.preview}
              width='220'
              height='231'
              style={{ border: 'none' }}
            ></iframe>
          ) : (
            <img
              src={file.preview}
              alt='Imagem do documento'
              className={classNames(styles.img, {
                [styles.greyImage]: Boolean(messageError)
              })}
            />
          )}
        </div>
      </div>
    )
  )

  return (
    <div className={styles.container}>
      <header>
        <span>{title}</span>
      </header>
      <div {...getRootProps({ className: 'dropzone' })}>
        <label
          className={classNames(styles.uploads, {
            [styles.uploadFile]: thumbs.length === 0,
            [styles.uploadedFile]: thumbs.length > 0
          })}
          onClick={() => setActive(!active)}
        >
          {thumbs.length === 0 && (
            <>
              <img src={uploadIcon} alt='Imagem de upload de arquivos' />
              <span>Arraste e solte um ou mais arquivos aqui ou clique</span>
              <input
                data-testid='photo-uploader'
                {...getInputProps()}
                disabled={active || thumbs.length > 0}
              />
            </>
          )}
          <aside className={styles.thumbsContainer}>
            {thumbs}
            <div className={styles.actions}>
              <XIcon onClick={onRemoveImage} />
              <SearchCircle onClick={() => null} />
            </div>
          </aside>
          {messageError && thumbs.length > 0 && (
            <DocValidationDisapproved
              title='Documento reprovado'
              reason={messageError}
            />
          )}
        </label>
      </div>
    </div>
  )
}
