import faker from '@faker-js/faker'

import {
  ProposalModel,
  ResourceOriginStatement,
  ProposalDetails,
  DataItem
} from '_types'

import { mockProperty, mockProponent } from '.'

export const mockResourceOriginStatement = (): ResourceOriginStatement => ({
  originType: 'financialApplication',
  name: faker.name.findName(),
  data: {
    bank: faker.company.companyName(),
    accountType: 'Conta corrente',
    account: '2356-6',
    agency: '0125',
    value: 'R$ 300.000'
  }
})

export const emptyResourceOriginStatement = (): ResourceOriginStatement => ({
  originType: '',
  name: '',
  data: {
    bank: '',
    accountType: '',
    account: '',
    agency: '',
    value: ''
  }
})

export const emptyProposal = (): ProposalModel => ({
  id: '',
  status: 'pending',
  statusProgress: 0,
  date: new Date(),
  details: [],
  proposalNumber: 0,
  queue: null,

  paymentType: '',
  proposalValue: 0,
  propertyValue: 0,
  comissionValue: 0,
  serviceCharge: 0,
  totalValue: 0,
  paymentDetails: {
    input: 0,
    balance: 0,
    installments: 1
  },
  fees: [
    { comissionValue: 0, whoPay: '-', target: '-', when: '-' },
    { comissionValue: 0, whoPay: '-', target: '-', when: '-' }
  ],
  observations: '',
  resourceOriginStatements: [],
  property: mockProperty(),
  proponents: []
})

export const mockProposal = (): ProposalModel => {
  return {
    ...emptyProposal(),
    id: faker.datatype.uuid(),
    status: faker.random.arrayElement([
      'pending',
      'waiting_signature',
      'sent_documents',
      'reproved_documents'
    ]),
    statusProgress: faker.datatype.number({ min: 0, max: 100 }),
    resourceOriginStatements: [mockResourceOriginStatement()],
    property: mockProperty(),
    proponents: [
      mockProponent(),
      mockProponent(),
      mockProponent(),
      mockProponent()
    ],
    proposal: {
      property: mockProperty()
    },
    details: [faker.datatype.number({ min: 0, max: 100 })]
  }
}

const dataTime: DataItem[] = [
  {
    id: '1',
    date: '01/09/2020 às 06:46',
    title: 'Documentos recebidos',
    status: 'accepted'
  },
  {
    id: '1',
    date: '01/09/2020 às 06:59',
    title: 'Proposta criada',
    status: 'accepted'
  },
  {
    id: '1',
    date: '02/09/2020 às 13:45',
    title: 'Cadastro',
    status: 'accepted'
  },
  {
    id: '1',
    date: '02/09/2020 às 12:05',
    title: 'Condições definidas',
    status: 'accepted'
  },
  {
    id: '1',
    date: '03/09/2020 às 09:30',
    title: 'Assinatura da proposta',
    status: 'accepted'
  },
  {
    id: '1',
    date: '05/09/2020 às 09:00',
    title: 'Documentos reprovados',
    status: 'accepted'
  },
  {
    id: '1',
    date: '05/09/2020 às 11:23',
    title: 'Proposta Aprovada',
    status: 'disapproved'
  },
  {
    id: '1',
    date: '05/09/2020 às 11:23',
    title: 'Cobrança de honorários',
    status: ''
  },
  {
    id: '1',
    date: '05/09/2020 às 11:23',
    title: 'Honorários pagos',
    status: ''
  },
  {
    id: '1',
    date: '05/09/2020 às 11:23',
    title: 'Proposta Aprovada',
    status: ''
  }
]

export const dataDetails = (numberProposal: string): ProposalDetails => {
  return {
    numberProposal: numberProposal,
    position: 2,
    dataTime: dataTime,
    property: mockProperty(),
    notification: [
      { key: 'sms', label: 'SMS', value: false, disabled: false },
      { key: 'whatsapp', label: 'Whatsapp', value: false, disabled: false },
      { key: 'email', label: 'E-mail', value: false, disabled: false },
      { key: 'push', label: 'Push Notification', value: false, disabled: false }
    ]
  }
}
