import { useState, useEffect } from 'react'

import { toast } from 'react-toastify'

import { emptyResourceOriginStatement } from '_mocks'
import { ResourceOriginStatement, Option } from '_types'
import { getTypeDeclarations } from 'services/api'

type UseDeclarationOriginResources = {
  fields: ResourceOriginStatement
  typeDeclarationOptions: Option[]
  handleChange: (field: keyof ResourceOriginStatement) => void
  loading: boolean
}

type UseDeclarationOriginResourcesProps = {
  initialFields?: ResourceOriginStatement
}

export const useDeclarationOriginResources = ({
  initialFields
}: UseDeclarationOriginResourcesProps): UseDeclarationOriginResources => {
  const [fields, setFields] = useState<ResourceOriginStatement>(
    initialFields || emptyResourceOriginStatement()
  )
  const [typeDeclarationOptions, setTypeDeclarationOptions] = useState<
    Option[]
  >([])
  const [loading, setLoading] = useState(false)

  const handleChange = (field: keyof ResourceOriginStatement) => (
    value: string | boolean
  ) => {
    setFields(state => ({ ...state, [field]: value }))
  }

  useEffect(() => {
    async function fetchTypeDeclaration() {
      try {
        setLoading(true)
        const response = await getTypeDeclarations()
        setTypeDeclarationOptions(response)
      } catch (error) {
        toast.error('Erro ao listar os tipos de declarações.')
      } finally {
        setLoading(false)
      }
    }

    fetchTypeDeclaration()
  }, [])

  return {
    fields,
    typeDeclarationOptions,
    handleChange,
    loading
  }
}
