import React from 'react'

import { ProposalReducer, ProposalTypes } from './proposal'
import { UserReducer, UserTypes } from './user'

export const initialState: StoreTypes.StateType = {
  proposal: ProposalReducer.InitialState,
  user: UserReducer.InitialState
}

export const Reducer: React.Reducer<
  StoreTypes.StateType,
  StoreTypes.ActionTypes
> = (state: StoreTypes.StateType, action: StoreTypes.ActionTypes) => {
  return {
    proposal: ProposalReducer.reducer(
      state.proposal,
      action as ProposalTypes.Actions
    ),
    user: UserReducer.reducer(state.user, action as UserTypes.Actions)
  }
}
