import { useState, useEffect, useCallback } from 'react'

import { toast } from 'react-toastify'

import { ProposalModel } from '_types'
import { listProposal } from 'services/api'

export type UseProposalList = {
  proposals: ProposalModel[]
  loading: boolean
}

export function useProposalList(): UseProposalList {
  const [proposals, setProposals] = useState<ProposalModel[]>([])
  const [loading, setLoading] = useState(false)

  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const response = await listProposal()
      setProposals(response)
    } catch (error) {
      toast.error('Erro na listagem de propostas')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  return {
    proposals,
    loading
  }
}
