import React from 'react'

import { Header } from 'components'
export type BaseLayoutProps = {
  showLinkToDashboard?: boolean
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({
  showLinkToDashboard = true,
  children
}) => {
  return (
    <>
      <Header showLinkToDashboard={showLinkToDashboard} />
      {children}
    </>
  )
}
