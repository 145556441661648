import React from 'react'

import logo from 'assets/svg/logoPagImovel.svg'
import safeIcon from 'assets/svg/logoSafe.svg'
import { Modal, Button } from 'components'

import styles from './WelcomeModal.module.scss'

export type WelcomeModalProps = {
  isOpen: boolean
  closeModal: () => void
}

export const WelcomeModal: React.FC<WelcomeModalProps> = ({
  isOpen = true,
  closeModal
}) => {
  return (
    <>
      {isOpen && (
        <Modal isOpen={isOpen} closeModal={closeModal} typeModal='xsmall'>
          <div className={styles.container}>
            <img src={logo} alt='Logo PagImovel' />
            <h1 className={styles.welcomeTitle}>
              Oi! Você acessou a plataforma Pagimovel®.
            </h1>
            <h1 className={styles.welcomeTitleMobile}>
              Seja bem vindo ao Pagimovel®.
            </h1>
            <div className={styles.welcomeContent}>
              <p className={styles.firstParagraph}>
                Um ambiente seguro onde você pode realizar todo o processo de
                compra do seu imóvel sem sair de casa.
              </p>
              <p>
                Para continuar precisamos que você nos envie uma{' '}
                <strong>
                  foto de frente e verso do seu documento de identidade.
                </strong>
              </p>
            </div>
            <div className={styles.moreInfo}>
              <img src={safeIcon} alt='Safe' />
              <p>
                Quer saber mais? <a href='http://'>Clique aqui.</a>
              </p>
            </div>
            <Button
              label='Enviar documentos'
              typeButton={'primary'}
              onClick={closeModal}
            ></Button>
          </div>
        </Modal>
      )}
    </>
  )
}
