import React from 'react'

import { Link } from 'react-router-dom'

import clicksign from 'assets/svg/clicksign.svg'
import { Modal, Button } from 'components'

import styles from './ClickSignModal.module.scss'

export type ClickSignModalProps = {
  isOpen: boolean
  closeModal: () => void
}

export const ClickSignModal: React.FC<ClickSignModalProps> = ({
  isOpen,
  closeModal
}) => {
  return (
    <Modal
      typeModal='small'
      isOpen={isOpen}
      closeModal={closeModal}
      closeButton={true}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <img src={clicksign} alt='icone de assinatura digital' />
        <h1>Assinatura digital de proposta.</h1>
        <p>
          Para sua segurança e comodidade utilizamos a plataforma ClickSign que
          funciona como um <strong>cartório digital</strong>. Vamos enviar um
          código para seu e-mail e você vai usá-lo para assinar a proposta
        </p>
        <p>
          Ainda com dúvida?
          <Link to='/'>Clique aqui e assista ao tutorial.</Link>
        </p>
        <Button label='Continuar' onClick={closeModal} />
      </div>
    </Modal>
  )
}
