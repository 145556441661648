import React, { useState } from 'react'

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

import { emptyProponent } from '_mocks'
import { ProponentModel } from '_types'
import { BottomNavigation, Button, ProponentSuccessModal } from 'components'
import { createProponent } from 'services/api'
import {
  personFormIsValid,
  spouseFormIsValid,
  witnessFormIsValid
} from 'utils/validators'

import { PersonForm } from './PersonForm/PersonForm'
import styles from './ProponentsForm.module.scss'
import { SpouseForm } from './SpouseForm/SpouseForm'
import { WitnessForm } from './WitnessForm/WitnessForm'

export type ProponentsFormProps = {
  initialData?: ProponentModel
  handleClose: () => void
}

export const ProponentsForm: React.FC<ProponentsFormProps> = ({
  initialData,
  handleClose
}) => {
  const [form, setForm] = useState<ProponentModel>(
    initialData || emptyProponent()
  )
  const [modal, setModal] = React.useState(false)
  const [step, setStep] = React.useState('proponentData')
  const [civilStateHidden, setCivilStateHidden] = React.useState(true)

  const isEdit = !!initialData

  const backButtonText = {
    proponentData: '',
    spouseData: 'Dados do proponente',
    witnessData:
      form.personType === 'pf' ? 'Dados do cônjuge' : 'Dados do proponente'
  }

  const handleCloseModal = () => {
    setModal(false)
    handleClose()
  }

  const handleChange = (step: string) => (field: string) => (
    value: string | boolean
  ) => {
    switch (step) {
      case 'proponentData':
        if (field === 'civil_status') {
          setCivilStateHidden(value !== 'married')
        }
        setForm(prev => ({ ...prev, [field]: value }))
        break
      case 'spouseData':
        if (field === 'civil_status') {
          setCivilStateHidden(value !== 'married')
        }
        setForm(prev => ({
          ...prev,
          spouse: { ...prev.spouse, [field]: value }
        }))
        break
      case 'witnessData':
        setForm(prev => ({
          ...prev,
          witness: { ...prev.witness, [field]: value }
        }))
        break
      default:
        return null
    }
  }

  const handleNext = () => {
    if (
      step === 'proponentData' &&
      form.civil_status === 'married' &&
      personFormIsValid(form)
    ) {
      return setStep('spouseData')
    }

    if (step === 'proponentData' && form.civil_status === 'married') {
      return setStep('spouseData')
    }

    if (step === 'proponentData' && personFormIsValid(form)) {
      return setStep('witnessData')
    }

    if (step === 'spouseData' && spouseFormIsValid(form)) {
      return setStep('witnessData')
    }
  }

  const handleBack = () => {
    if (step === 'witnessData' && form.civil_status === 'married') {
      return setStep('spouseData')
    }
    if (step === 'spouseData' || step === 'witnessData') {
      return setStep('proponentData')
    }
  }

  const handleSaveProponent = async () => {
    if (witnessFormIsValid(form)) {
      try {
        await createProponent(form)
        setModal(true)
      } catch (error) {
        setModal(false)
      }
    }
  }

  const isDisableButton = (): boolean => {
    let res

    if (step === 'proponentData') res = !personFormIsValid(form)
    if (step === 'spouseData') res = !spouseFormIsValid(form)
    if (step === 'witnessData') res = !witnessFormIsValid(form)

    return !!res
  }

  const MainButton =
    step !== 'witnessData' ? (
      <Button
        label='Continuar'
        onClick={handleNext}
        icon={<IoIosArrowForward size='16' />}
        isDisable={isDisableButton()}
      />
    ) : (
      <Button
        label='Salvar proponente'
        onClick={handleSaveProponent}
        isDisable={isDisableButton()}
      />
    )
  const Cancelar = (
    <div className={styles.button}>
      <button onClick={() => handleClose()}>Cancelar</button>
    </div>
  )

  const BackButton = (
    <div className={styles.button}>
      {backButtonText[step as keyof typeof backButtonText] !== '' && (
        <IoIosArrowBack />
      )}
      <button onClick={handleBack}>
        {backButtonText[step as keyof typeof backButtonText]}
      </button>
    </div>
  )

  return (
    <div className={styles.container}>
      {step === 'proponentData' && (
        <PersonForm
          fields={form}
          handleChange={handleChange(step)}
          civilStateHidden={civilStateHidden}
          isEdit={isEdit}
        />
      )}
      {step === 'spouseData' && (
        <SpouseForm fields={form} handleChange={handleChange(step)} />
      )}

      {step === 'witnessData' && (
        <WitnessForm fields={form} handleChange={handleChange(step)} />
      )}
      <div className={styles.bottom}>
        <BottomNavigation
          mainButton={MainButton}
          secondaryButton={Cancelar}
          backButton={BackButton}
        />
      </div>

      <ProponentSuccessModal isOpen={modal} closeModal={handleCloseModal} />
    </div>
  )
}
