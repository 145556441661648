import React from 'react'

import { ResourceOriginStatement } from '_types'
import { Form, BottomNavigation, Button, Loader } from 'components'
import { isValidFullName, validationRules } from 'utils/validators'

import styles from './DeclarationOriginResources.module.scss'
import { useDeclarationOriginResources } from './useDeclarationOriginResources'

type DeclarationOriginResourcesProps = {
  handleClose: () => void
  initialFields?: ResourceOriginStatement
}

export const DeclarationOriginResources: React.FC<DeclarationOriginResourcesProps> = ({
  handleClose,
  initialFields
}) => {
  const {
    fields,
    handleChange,
    typeDeclarationOptions,
    loading
  } = useDeclarationOriginResources({ initialFields })

  const form = [
    {
      type: 'select',
      label: 'Tipo de Declaração',
      selected: fields.originType,
      onChange: handleChange('originType'),
      options: typeDeclarationOptions,
      required: true,
      size: 'medium'
    },
    {
      label: 'Nome completo',
      type: 'text',
      value: fields.name,
      onChange: handleChange('name'),
      isValid: isValidFullName,
      required: true,
      capitalize: true,
      size: 'medium',
      rules: validationRules.name
    }
  ]
  // TODO implmentar os fields de cada tipo de declaracao

  if (loading) return <Loader />

  return (
    <div className={styles.container}>
      <h1>Declaração de origem de recursos</h1>
      <Form fields={form} />

      <div className={styles.bottom}>
        <BottomNavigation
          mainButton={
            <Button label='Salvar' onClick={() => null} isDisable={false} />
          }
          secondaryButton={
            <div className={styles.button}>
              <button onClick={handleClose}>Cancelar</button>
            </div>
          }
        />
      </div>
    </div>
  )
}
