import React from 'react'

import classNames from 'classnames'
import { Link } from 'react-router-dom'

import noImage from 'assets/images/noPhoto.webp'

import styles from './Image.module.scss'

export type ImageProps = {
  alt: string
  title?: string
  link?: string
  source: string
  noPhoto?: string
}

export const Image: React.FC<ImageProps> = ({
  alt,
  title = alt,
  link = '',
  source,
  noPhoto = noImage
}) => {
  const [newSource, setSource] = React.useState(source)

  const Component = () => (
    <img
      className={classNames(styles.image, 'lazy')}
      src={newSource}
      alt={alt}
      title={title}
      onError={() => setSource(noPhoto)}
    />
  )

  return (
    <>
      {link !== '' ? (
        <Link data-testid='linkTo' to={`/${link}`}>
          <Component />
        </Link>
      ) : (
        <Component />
      )}
    </>
  )
}
