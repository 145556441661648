import * as mock from './mock'
import {
  CancelProposal,
  GetProposalData,
  ListProposal,
  SaveProposalDraft,
  SendProposalToAnalysis
} from './types'

export const listProposal: ListProposal = mock.listProposal
export const getProposalData: GetProposalData = mock.getProposalData
export const saveProposalDraft: SaveProposalDraft = mock.saveProposalDraft
export const sendProposalToAnalysis: SendProposalToAnalysis =
  mock.sendProposalToAnalysis
export const cancelProposal: CancelProposal = mock.cancelProposal
