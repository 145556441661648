import React from 'react'

import styles from './svg.module.scss'

type LinkedinTypes = {
  viewBox?: string
  color?:
    | 'primaryColor'
    | 'darkColor'
    | 'whiteColor'
    | 'greenColor'
    | 'greyColor'
    | 'blackLight'
}
const Vacancies: React.FC<LinkedinTypes> = ({
  viewBox = '0 0 32 32',
  color = 'primaryColor'
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='19px'
      height='100%'
      viewBox={viewBox}
    >
      <defs>
        <path
          id='vacancies'
          d='M2.116 10.036a1.094 1.094 0 0 1 .489-1.467L15.51 2.115a1.1 1.1 0 0 1 .978 0L29.395 8.57a1.094 1.094 0 0 1-.978 1.956L16 4.317 3.583 10.525c-.54.27-1.197.051-1.467-.489zM30 28.906C30 29.51 29.51 30 28.906 30H3.094a1.094 1.094 0 1 1 0-2.188h5.36v-2.114h-3.21c-.603 0-1.093-.49-1.093-1.094V21.55a4.88 4.88 0 0 1 2.344-4.155l2.014-6.043a1.094 1.094 0 0 1 1.038-.748h12.906c.47 0 .889.301 1.038.748l2.014 6.043a4.88 4.88 0 0 1 2.344 4.155v3.054c0 .604-.49 1.094-1.094 1.094h-3.208v2.114h5.359c.604 0 1.094.49 1.094 1.094zM8.913 17.057h14.174l-1.422-4.265h-11.33l-1.422 4.265zM6.34 23.51h19.32v-1.96a2.68 2.68 0 0 0-1.326-2.305H7.665A2.68 2.68 0 0 0 6.34 21.55v1.96zm4.302 4.302H21.36v-2.114H10.641v2.114z'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='b'>
          <use xlinkHref='#vacancies' />
        </mask>
        <use
          className={styles[color]}
          fillRule='nonzero'
          xlinkHref='#vacancies'
        />
        <g mask='url(#b)'>
          <path d='M0 0h32v32H0z' />
        </g>
      </g>
    </svg>
  )
}

export default Vacancies
