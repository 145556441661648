import React from 'react'

import { RadioButton, Input, SelectInput } from 'components'

import { RadioButtonProps } from '../radioButton/RadioButton'
import styles from './Form.module.scss'

export type FormProps = {
  title?: string
  fields: any[]
  className?: string
}

export const Form: React.FC<FormProps> = ({
  title = '',
  fields,
  className = ''
}) => {
  return (
    <form className={`${className} ${styles.form}`}>
      {title && <h3> {title}</h3>}

      {fields.map(field => {
        if (field.hidden) return null
        if (field.type === 'select') {
          return <SelectInput data-testid='selectInput' {...field} />
        }

        if (field.type === 'radio') {
          return (
            <fieldset data-testid='fieldset'>
              {field.label && (
                <legend className={styles.radioLabel}>{field.label}</legend>
              )}
              <div className={styles.radioGroup}>
                {field.options &&
                  field.options.map((option: RadioButtonProps) => (
                    <RadioButton key={option.option} {...option} />
                  ))}
              </div>
            </fieldset>
          )
        }

        return <Input data-testid='input' key={field.label} {...field} />
      })}
    </form>
  )
}
