/* eslint-disable import/namespace */
import React from 'react'

import * as Icons from 'assets/svg/svgs'

export const ICONS_TYPES = Object.keys(Icons).map(_icon =>
  _icon.toLocaleLowerCase()
)

type IconTypes = {
  color?:
    | 'primaryColor'
    | 'darkColor'
    | 'whiteColor'
    | 'greenColor'
    | 'greyColor'
    | 'blackLight'
  type: typeof ICONS_TYPES[number]
  className?: string
}

const getIcon = (type: string) =>
  Object.entries(Icons).find(
    ([key]) => key.toLocaleLowerCase() === type.toLocaleLowerCase()
  )

const Icon: React.FC<IconTypes> = ({
  type,
  color = 'greyColor',
  className = ''
}) => {
  const [, value] = getIcon(type) || []
  return (
    <span className={className}>
      {value &&
        React.createElement(value, {
          color
        })}
    </span>
  )
}

export default React.memo(Icon)
