import React from 'react'

export type ExclamationProps = {
  width?: string
  height?: string
  color?: string
}

export const Exclamation: React.FC<ExclamationProps> = ({
  width = 20,
  height = 20,
  color = '#FF4040'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 9.99951C18 14.4178 14.4183 17.9995 10 17.9995C5.58172 17.9995 2 14.4178 2 9.99951C2 5.58123 5.58172 1.99951 10 1.99951C14.4183 1.99951 18 5.58123 18 9.99951ZM11.5 8.49951C11.5 10.4325 10.8284 11.9995 10 11.9995C9.17157 11.9995 8.5 10.4325 8.5 8.49951C8.5 6.56651 9.17157 4.99951 10 4.99951C10.8284 4.99951 11.5 6.56651 11.5 8.49951ZM11.5 14.4995C11.5 15.3279 10.8284 15.9995 10 15.9995C9.17157 15.9995 8.5 15.3279 8.5 14.4995C8.5 13.6711 9.17157 12.9995 10 12.9995C10.8284 12.9995 11.5 13.6711 11.5 14.4995Z'
        fill='#3D3D3D'
      />
      <mask
        id='mask0_2011_38879'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='2'
        y='1'
        width='16'
        height='17'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18 9.99951C18 14.4178 14.4183 17.9995 10 17.9995C5.58172 17.9995 2 14.4178 2 9.99951C2 5.58123 5.58172 1.99951 10 1.99951C14.4183 1.99951 18 5.58123 18 9.99951ZM11.5 8.49951C11.5 10.4325 10.8284 11.9995 10 11.9995C9.17157 11.9995 8.5 10.4325 8.5 8.49951C8.5 6.56651 9.17157 4.99951 10 4.99951C10.8284 4.99951 11.5 6.56651 11.5 8.49951ZM11.5 14.4995C11.5 15.3279 10.8284 15.9995 10 15.9995C9.17157 15.9995 8.5 15.3279 8.5 14.4995C8.5 13.6711 9.17157 12.9995 10 12.9995C10.8284 12.9995 11.5 13.6711 11.5 14.4995Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_2011_38879)'>
        <rect y='-0.000488281' width={width} height={height} fill={color} />
      </g>
    </svg>
  )
}
