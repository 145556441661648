import React, { useState } from 'react'

import classnames from 'classnames'

import { ReactComponent as EditIcon } from 'assets/svg/edit.svg'
import { Input } from 'components'
import { StateFormFields } from 'store/user/types'

import styles from './ProfileData.module.scss'

type ButtonEditProps = {
  editState: boolean
  setEditState: (state: boolean) => void
  onSubmit: () => void
  className?: string
}

export type ProfileDataProps = {
  initialFields: {
    name: string
    email: string
    phone: string
    password?: string
  }
}

export const ProfileData: React.FC<ProfileDataProps> = ({ initialFields }) => {
  const [form, setForm] = useState(initialFields)

  const [editEmail, setEditEmail] = useState(false)
  const [editPassword, setEditPassword] = useState(false)

  const handleChange = (field: StateFormFields, value: string) => {
    setForm(prev => ({ ...prev, [field]: value }))
  }

  const ButtonEdit: React.FC<ButtonEditProps> = ({
    editState,
    setEditState,
    onSubmit,
    className
  }) =>
    !editState ? (
      <button
        className={classnames(className)}
        onClick={() => {
          setEditState(!editState)
        }}
      >
        <p>Editar</p>
        <EditIcon />
      </button>
    ) : (
      <button
        className={classnames(className)}
        onClick={() => {
          setEditState(!editState)
          onSubmit()
        }}
      >
        <p>Salvar</p>
      </button>
    )

  return (
    <div>
      <h1 className={styles.titleProfileData}>Dados do perfil</h1>
      <section className={styles.container}>
        <Input label='NOME' value={form.name} disabled />
        <div className={styles.editInput}>
          <Input
            label='E-MAIL'
            value={form.email}
            onChange={value => handleChange('email', value)}
            disabled={!editEmail}
            className={classnames({ [styles.focus]: editEmail })}
            required={false}
          />
          <ButtonEdit
            editState={editEmail}
            setEditState={setEditEmail}
            onSubmit={() => null}
            className={styles.icon}
          />
        </div>
        <Input
          label='TELEFONE'
          value={form.phone}
          disabled
          mask='(**) *****-****'
        />
        <div className={styles.editInput}>
          <Input
            type='password'
            label='SENHA'
            value={form.password || ''}
            onChange={value => handleChange('password', value)}
            disabled={!editPassword}
            className={classnames({ [styles.focus]: editPassword })}
          />
          <ButtonEdit
            editState={editPassword}
            setEditState={setEditPassword}
            onSubmit={() => null}
            className={styles.icon}
          />
        </div>
      </section>
    </div>
  )
}
