import React from 'react'

import { Toggle } from 'components'
import { useUser } from 'store/hooks'
import { changeNotifionsOptions } from 'store/user/actions'
import { StatesMessages, InnerFieldMessages } from 'store/user/types'

import styles from './NotificationsProfile.module.scss'
import { getOptionsMessages } from './utils'

export const NotificationsProfile: React.FC = () => {
  const { dispatch, user } = useUser()

  const { informationsMessages, promotionsMessages } = user

  const { informationsOptions, promotionsOptions } = getOptionsMessages()

  const handleOption = (
    field: StatesMessages,
    innerField: InnerFieldMessages
  ) => {
    dispatch(changeNotifionsOptions(field, innerField))
  }

  return (
    <section className={styles.container}>
      <h1>Notificações</h1>
      <div className={styles.options}>
        <article>
          <p className={styles.helpText}>
            Escolha os canais e tipos de mensagem que você deseja receber
          </p>
          <h2 className={styles.sectionTitle}>Mensagens informativas</h2>
          <div className={styles.toggleSection}>
            {informationsOptions.map(({ id, option }) => (
              <Toggle
                key={`informations-${id}`}
                id={`informations-${id}`}
                option={option}
                checked={informationsMessages[id].checked}
                disabled={informationsMessages[id].disabled}
                onChange={() =>
                  handleOption('informationsMessages', id as InnerFieldMessages)
                }
              />
            ))}
          </div>
        </article>
        <article>
          <h2 className={styles.sectionTitle}>Mensagens promocionais</h2>
          <div className={styles.toggleSection}>
            {promotionsOptions.map(({ id, option }) => (
              <Toggle
                key={`promotions-${id}`}
                id={`promotions-${id}`}
                option={option}
                checked={promotionsMessages[id].checked}
                disabled={promotionsMessages[id].disabled}
                onChange={() =>
                  handleOption('promotionsMessages', id as InnerFieldMessages)
                }
              />
            ))}
          </div>
        </article>
      </div>
    </section>
  )
}
