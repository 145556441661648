import React from 'react'

import { Modal, Button, Loader } from 'components'

import styles from './ValidatingDocModal.module.scss'

export type ValidatingDocModalProps = {
  isOpen: boolean
  closeModal: () => void
  onClick?: () => void
}

export const ValidatingDocModal: React.FC<ValidatingDocModalProps> = ({
  isOpen,
  closeModal,
  onClick = () => null
}) => {
  const handleClick = () => {
    onClick()
    closeModal()
  }

  return (
    <Modal
      typeModal='small'
      isOpen={isOpen}
      closeModal={closeModal}
      closeButton={true}
      className={styles.modalContainer}
    >
      <div className={styles.container}>
        <header>
          <Loader />
          <h1 className={styles.title}>Validando documento</h1>
        </header>
        <p className={styles.text}>
          Aguarde enquanto estamos validando o seu documento. <br /> Vai levar
          só alguns segundos.
        </p>
        <footer>
          <Button
            label='Cancelar envio'
            className={styles.btnSend}
            typeButton='outline'
            onClick={() => handleClick()}
          />
        </footer>
      </div>
    </Modal>
  )
}
