import React from 'react'

import { FaBars, FaTimes } from 'react-icons/fa'

import { SideMenu, Header } from 'components'
import { isMobile } from 'utils/deviceDetector'

import styles from './BaseLayoutWithSideMenu.module.scss'

export const BaseLayoutWithSideMenu: React.FC = ({ children }) => {
  const [openMenu, setOpenMenu] = React.useState(false)

  const handleClickMenu = () => setOpenMenu(prev => !prev)

  const mobileMenu = !openMenu ? (
    <FaBars data-testid='menu' size={20} onClick={handleClickMenu} />
  ) : (
    <FaTimes data-testid='menu-opened' size={20} onClick={handleClickMenu} />
  )
  return (
    <>
      <Header showLinkToDashboard={!isMobile()}>
        {isMobile() && mobileMenu}
      </Header>
      <div className={styles.container}>
        {(openMenu || !isMobile()) && <SideMenu />}
        {children}
      </div>
    </>
  )
}
