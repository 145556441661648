import React from 'react'

import { Button, CurrencyInput, SelectInput } from 'components'
import { useProposal } from 'store/hooks'
import { format } from 'utils/formatters/currency'
import { paymentOptions } from 'utils/selectOptions'

import styles from './YourProposalSection.module.scss'

type YourProposalSectionProps = {
  handleChangeProposal: (key: string) => (value: string) => void
}

export const YourProposalSection: React.FC<YourProposalSectionProps> = ({
  handleChangeProposal
}) => {
  const { proposal } = useProposal()

  return (
    <section className={styles.yourProposalSection}>
      <h2>Sua proposta</h2>
      <div className={styles.inputValues}>
        <SelectInput
          label='Selecione uma forma de pagamento'
          options={paymentOptions}
          selected={proposal.paymentType}
          onChange={handleChangeProposal('paymentType')}
          className={styles.whiteInput}
          size='xmedium'
        />
        <CurrencyInput
          label='Qual o valor da sua proposta'
          value={proposal.proposalValue}
          onChange={handleChangeProposal('proposalValue')}
          size='xmedium'
          className={styles.greyInput}
        />
        <Button label='Atualizar valor' />
      </div>
      <div className={styles.summaryValues}>
        <div className={styles.card}>
          <p>Valor do imóvel:</p>
          <p>{format(proposal.propertyValue)}</p>
        </div>
        <span>{'+'}</span>
        <div className={styles.card}>
          <p>Valor da comissão: </p>
          <p>{format(proposal.comissionValue)}</p>
        </div>
        <span>{'+'}</span>
        <div className={styles.card}>
          <p>Taxa de serviço:</p>
          <p>{format(proposal.serviceCharge)}</p>
        </div>
        <span>{'-'}</span>
        <div className={styles.card}>
          <p>Cupom desconto:</p>
          <p>{format(proposal.serviceCharge)}</p>
        </div>
        <span>{'='}</span>
        <div className={styles.card}>
          <p>Valor total:</p>
          <p>{format(proposal.totalValue)}</p>
        </div>
      </div>
    </section>
  )
}
