import React from 'react'

import classNames from 'classnames'

import styles from './Text.module.scss'

type colors =
  | 'primaryColor'
  | 'darkBlueColor'
  | 'lightBlueColor'
  | 'blackColor'
  | 'resaleGreenColor'
  | 'greyColor'
  | 'whiteColor'
  | 'textColor'
type fontSizes =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'regular'
  | 'large'
  | 'xlarge'

export type TextProps = {
  color?: colors
  fontSize?: fontSizes
  style?: React.CSSProperties
  bold?: boolean
  className?: string
  id?: string
  title?: string
  onClick?: () => void
}

export const Text: React.FC<TextProps> = ({
  children,
  color = 'darkBlueColor',
  fontSize = 'small',
  style = {},
  bold = false,
  className = '',
  id = '',
  title = '',
  onClick = () => null
}) => {
  return (
    <p
      id={id}
      onClick={onClick}
      title={title}
      className={classNames(
        styles.text,
        styles[color],
        styles[fontSize],
        className,
        {
          [styles.bold]: bold
        }
      )}
      style={style}
    >
      {children}
    </p>
  )
}
