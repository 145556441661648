import React from 'react'

import classnames from 'classnames'

import { ProponentReducedModel } from '_types'
import { Modal } from 'components/modal/Modal'

import styles from './DocumentationListModal.module.scss'

type DocumentationListModalProps = {
  isOpen: boolean
  closeModal: () => void
  proponent: ProponentReducedModel
}

export const DocumentationListModal: React.FC<DocumentationListModalProps> = ({
  isOpen,
  closeModal,
  proponent
}) => {
  return (
    <Modal
      className={styles.modal}
      typeModal='small'
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <div className={styles.container}>
        <header>
          <h1>Documentos requisitados</h1>
          <h2>{proponent.name}</h2>
        </header>

        <div className={styles.documents}>
          <section>
            <h3>Documentos do proponente</h3>
            <ul>
              {proponent.statusDocumentation.proponents.map(document => (
                <li
                  key={document.title}
                  className={classnames({
                    [styles.reproved]: document.status === 'reproved',
                    [styles.pending]: document.status === 'pending'
                  })}
                >
                  <p>{document.title}</p>
                  {document.status === 'reproved' && (
                    <strong>Documento reprovado</strong>
                  )}
                  {document.status === 'pending' && (
                    <strong>Documento em validação</strong>
                  )}
                  {document.reason && <span>Motivo: {document.reason}</span>}
                </li>
              ))}
            </ul>
          </section>

          <section>
            <h3>Documentos do cônjuge</h3>
            <ul>
              {proponent.statusDocumentation.spouse.map(document => (
                <li
                  key={document.title}
                  className={classnames({
                    [styles.reproved]: document.status === 'reproved',
                    [styles.pending]: document.status === 'pending'
                  })}
                >
                  <p>{document.title}</p>
                  {document.status === 'reproved' && (
                    <strong>Documento reprovado</strong>
                  )}
                  {document.status === 'pending' && (
                    <strong>Documento em validação</strong>
                  )}
                  {document.reason && <span>Motivo: {document.reason}</span>}
                </li>
              ))}
            </ul>
          </section>

          <section>
            <h3>Documentos complementares</h3>
            <ul>
              {proponent.statusDocumentation.complementary.map(document => (
                <li
                  key={document.title}
                  className={classnames({
                    [styles.reproved]: document.status === 'reproved',
                    [styles.pending]: document.status === 'pending'
                  })}
                >
                  <p>{document.title}</p>
                  {document.status === 'reproved' && (
                    <strong>Documento reprovado</strong>
                  )}
                  {document.status === 'pending' && (
                    <strong>Documento em validação</strong>
                  )}
                  {document.reason && <span>Motivo: {document.reason}</span>}
                </li>
              ))}
            </ul>
          </section>
        </div>
      </div>
    </Modal>
  )
}
