import React from 'react'

import styles from './BottomNavigation.module.scss'

export type BottomNavigationProps = {
  backButton?: JSX.Element
  mainButton: JSX.Element
  secondaryButton?: JSX.Element
}

export const BottomNavigation: React.FC<BottomNavigationProps> = ({
  backButton,
  mainButton,
  secondaryButton
}) => {
  return (
    <div className={styles.container}>
      <div>{backButton}</div>
      <div className={styles['button-wrapper']}>
        {secondaryButton}
        {mainButton}
      </div>
    </div>
  )
}
