import React from 'react'

import { FileType, GenericListDocuments } from '_types'
import { DocumentCard } from 'components'
import useStore from 'store'
import { updateSpouseDocuments } from 'store/proposal/actions'

import styles from './index.module.scss'
type SpouseStepProps = {
  spouseDocuments: Partial<GenericListDocuments>
  idProponent: string
}

export const SpouseStep: React.FC<SpouseStepProps> = ({
  spouseDocuments,
  idProponent
}) => {
  const { dispatch } = useStore()
  const handleChangeFiles = (
    documentKey: keyof GenericListDocuments,
    files: FileType[]
  ) => {
    dispatch(updateSpouseDocuments(idProponent, documentKey, files))
  }
  return (
    <div className={styles.container}>
      {Object.entries(spouseDocuments).map(
        ([documentKey, document]) =>
          document && (
            <DocumentCard
              title={document.title}
              status={document.status}
              files={document.files}
              onChangeFiles={files =>
                handleChangeFiles(
                  documentKey as keyof GenericListDocuments,
                  files
                )
              }
              key={documentKey}
            />
          )
      )}
    </div>
  )
}
