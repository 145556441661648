import React from 'react'

import { BsFillQuestionCircleFill } from 'react-icons/bs'

import { ResourceOriginStatement } from '_types'
import proposalIcon from 'assets/svg/proposal.svg'
import { AddButton, OriginResourcesCard } from 'components'
import { PropertyContainer, DeclarationOriginResources } from 'containers'
import { useToggle } from 'hooks/useToogle'
import { useProposal } from 'store/hooks'
import { changeProposal } from 'store/proposal/actions'

import proposalStyles from '../../Proposal.module.scss'
import { DiscountCouponSection } from './DiscountCouponSection/DiscountCouponSection'
import { FeesDetailsSection } from './FessDetailsSection/FeesDetailsSection'
import { HowWillBePaidSection } from './HowWillBePaidSection/HowWillBePaidSection'
import { PropertyValuesSection } from './PropertyValuesSection/PropertyValuesSection'
import styles from './ProposalContainer.module.scss'
import { YourProposalSection } from './YourProposalSection/YourProposalSection'

export const ProposalContainer: React.FC = () => {
  const originResourcesFormToggle = useToggle()
  const originResourcesEditFormToggle = useToggle<ResourceOriginStatement>()

  const { proposal, dispatch } = useProposal()

  function handleChangeProposal(key: string) {
    return (value: string) => {
      const mutableState = {
        ...proposal,
        [key]: value
      }

      dispatch(changeProposal(mutableState))
    }
  }

  function handleChangePaymentDetails(key: string) {
    return (value: string) => {
      const mutableState = {
        ...proposal,
        paymentDetails: {
          ...proposal.paymentDetails,
          [key]: value
        }
      }

      dispatch(changeProposal(mutableState))
    }
  }

  if (originResourcesFormToggle.isOpen) {
    return (
      <DeclarationOriginResources
        handleClose={originResourcesFormToggle.close}
      />
    )
  }

  if (originResourcesEditFormToggle.isOpen) {
    return (
      <DeclarationOriginResources
        initialFields={originResourcesEditFormToggle.params}
        handleClose={originResourcesEditFormToggle.close}
      />
    )
  }

  return (
    <>
      <div className={styles.container}>
        <div>
          <header className={styles.title}>
            <img src={proposalIcon} alt='Proposal icon' />
            <h1>Proposta</h1>
          </header>
          <PropertyValuesSection />
          <DiscountCouponSection />
          <YourProposalSection handleChangeProposal={handleChangeProposal} />
          <HowWillBePaidSection
            handleChangePaymentDetails={handleChangePaymentDetails}
          />
          <FeesDetailsSection handleChangeProposal={handleChangeProposal} />
        </div>
        <div>
          <section className={styles.originResourcesSection}>
            <h2>
              Declaração de origem de recursos{' '}
              <BsFillQuestionCircleFill color='#00BFB2' />
            </h2>
            <div className={styles.originResourcesContainer}>
              <AddButton
                label='Adicionar declaração de origem'
                onClick={originResourcesFormToggle.open}
              />
              {proposal.resourceOriginStatements.map(item => (
                <OriginResourcesCard
                  key={item.originType}
                  originType={item.originType}
                  resourceOriginStatement={item.data}
                  handleRemove={() => null}
                  handleEdit={() => originResourcesEditFormToggle.open(item)}
                />
              ))}
            </div>
          </section>
        </div>
      </div>
      <div className={proposalStyles.card}>
        <PropertyContainer />
      </div>
    </>
  )
}
