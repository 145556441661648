import React from 'react'

import { FinancialApplicationType, ResourceOriginStatementData } from '_types'
import { ReactComponent as LixeiraIcon } from 'assets/svg/lixeira.svg'
import { Button } from 'components'

import styles from './OriginResourcesCard.module.scss'

export interface OriginResourcesCardProps {
  originType: string
  resourceOriginStatement: ResourceOriginStatementData
  handleRemove: () => void
  handleEdit: () => void
}

export const OriginResourcesCard: React.FC<OriginResourcesCardProps> = ({
  originType,
  resourceOriginStatement,
  handleRemove,
  handleEdit
}) => {
  // TODO implementar outros com a integracao
  const types = {
    financialApplication: {
      title: 'Aplicação financeira',
      render: () => {
        const resourceOrigin = resourceOriginStatement as FinancialApplicationType
        return (
          <article className={styles.financialApplication}>
            <h1>
              Banco: <p>{resourceOrigin.bank}</p>
            </h1>
            <h1>
              Tipo de conta: <p>{resourceOrigin.accountType}</p>
            </h1>
            <h1>
              Conta: <p>{resourceOrigin.account}</p>
            </h1>
            <h1>
              Agência: <p>{resourceOrigin.agency}</p>
            </h1>
            <h1>
              Valor: <p>{resourceOrigin.value}</p>
            </h1>
          </article>
        )
      }
    }
  }

  const { title, render } = types[originType as keyof typeof types]

  return (
    <main className={styles.card}>
      <h1>{title}</h1>
      <div>{render()}</div>
      <div className={styles.options}>
        <LixeiraIcon className={styles.trash} onClick={handleRemove} />
        <Button label='Editar' onClick={handleEdit} className={styles.edit} />
      </div>
    </main>
  )
}
