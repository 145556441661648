import React from 'react'

import { format } from 'date-fns'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'

import { ProposalModel } from '_types'
import alertIcon from 'assets/svg/alerta.svg'
import okayIcon from 'assets/svg/okayCheck.svg'
import waitingIcon from 'assets/svg/waiting.svg'
import { Button, ProgressBar } from 'components'
import { Routes } from 'router/routes'

import styles from './Proposal.module.scss'

export type ProposalProps = {
  proposal: ProposalModel
}

export const Proposal: React.FC<ProposalProps> = ({ proposal }) => {
  const [openDetails, setOpenDetails] = React.useState(false)

  const history = useHistory()

  const iconsMap = {
    reproved_documents: <img src={alertIcon} alt='Reprovado' />,
    waiting_signature: <img src={waitingIcon} alt='Aguardando assinatura' />,
    sent_documents: <img src={okayIcon} alt='Documentos Enviados' />
  }

  const statusMap = {
    waiting_signature: 'Aguardando assinatura do proponente',
    sent_documents: 'Documentos enviados',
    reproved_documents: 'Documento reprovado'
  }

  const icon = iconsMap[proposal.status as keyof typeof iconsMap]
  const statusDescribe = statusMap[proposal.status as keyof typeof statusMap]

  const Details = () => (
    <>
      <div className={styles.line}></div>
      <div className={styles.details}>
        <div className={styles.statusDetails}>
          {icon}
          {statusDescribe}
        </div>
        {proposal.details && (
          <button
            className={styles.detailsExpand}
            onClick={() => setOpenDetails(!openDetails)}
          >
            <p className={styles.dropDownText}>{'Ver linha do tempo'}</p>
            <RiArrowDropDownLine size={20} />
          </button>
        )}
      </div>
    </>
  )

  const buttonLabelMap = {
    waiting_signature: 'Refazer proposta',
    sent_documents: 'Ver contrato',
    reproved_documents: 'Revisar documentos'
  }

  const buttonLabel =
    buttonLabelMap[proposal.status as keyof typeof buttonLabelMap] ||
    'Continuar proposta'

  return (
    <article
      className={`${styles.container} ${openDetails ? styles.open : ''}`}
    >
      <div className={styles.propertyData}>
        <div className={styles.internDataContainer}>
          <div className={styles.proposalNumberMob}>
            N° proposta: {proposal.proposalNumber}
          </div>
          <ProgressBar progress={proposal.statusProgress} />
          <div className={styles.internData}>
            <img src={proposal.property.photo} alt={proposal.property.name} />
            <div className={styles.propertyTextData}>
              <div className={styles.propertyTextDataContainer}>
                <time>{format(proposal.date, "dd/mm/yyyy 'às' h:mm")}</time>

                <h2>{proposal.property.name}</h2>

                <address>
                  {proposal.property.city} / {proposal.property.state}
                </address>

                <p>ID: {proposal.id}</p>

                {proposal.queue && <p>Posição na fila: {proposal.queue}</p>}
              </div>
              <div className={styles.proposalNumber}>
                N° proposta: {proposal.proposalNumber}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <Details />
        {openDetails && (
          <ul className={styles.content}>
            <li>li 1</li>
            <li>li 2</li>
            <li>li 3</li>
            <li>li 4</li>
          </ul>
        )}
      </div>
      <div className={styles.line}></div>
      <div className={styles.buttons}>
        <Button label='Fale conosco' typeButton='outline' />
        <Button label='Cancelar proposta' typeButton='outline' />
        <Button
          label='Ver detalhes'
          typeButton='outline'
          onClick={() =>
            history.push(Routes.PROPOSALS(proposal.proposalNumber.toString()))
          }
        />
        <Button
          label={buttonLabel}
          onClick={() => history.push(Routes.PROPOSAL(proposal.id, 4))}
        />
      </div>
    </article>
  )
}
