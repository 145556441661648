import React from 'react'

import { ProponentModel } from '_types'
import {
  NotificationsProfile,
  ProponentDocument,
  ProfileData,
  DocumentationListModal
} from 'components'
import {
  DocumentationForm,
  DeclinedDocumentationForm,
  ProponentsForm
} from 'containers'
import { useToggle } from 'hooks/useToogle'
import { useUser } from 'store/hooks'
import { BaseLayoutWithSideMenu } from 'template'

import styles from './Profile.module.scss'

export function ProfilePage(): JSX.Element {
  const documentationModalToggle = useToggle<ProponentModel>()
  const documentationFormToggle = useToggle<ProponentModel>()
  const declinedDocumentationFormToggle = useToggle<ProponentModel>()
  const editProponentFormToggle = useToggle<ProponentModel>()

  const { user } = useUser()

  if (documentationFormToggle.isOpen) {
    return (
      <BaseLayoutWithSideMenu>
        <div className={styles.content}>
          <header>
            Meu perfil {'>'} {user.proponent.name}
          </header>
          <DocumentationForm
            proponent={documentationFormToggle.params}
            handleCloseForm={documentationFormToggle.close}
            isVisibleHeader={false}
          />
        </div>
      </BaseLayoutWithSideMenu>
    )
  }

  if (declinedDocumentationFormToggle.isOpen) {
    return (
      <BaseLayoutWithSideMenu>
        <div className={styles.content}>
          <header>
            Meu perfil {'>'} {user.proponent.name}
          </header>
          <DeclinedDocumentationForm
            proponent={declinedDocumentationFormToggle.params}
            handleCloseForm={declinedDocumentationFormToggle.close}
            isVisibleHeader={false}
          />
        </div>
      </BaseLayoutWithSideMenu>
    )
  }

  if (editProponentFormToggle.isOpen) {
    return (
      <BaseLayoutWithSideMenu>
        <ProponentsForm
          handleClose={editProponentFormToggle.close}
          initialData={editProponentFormToggle.params}
        />
      </BaseLayoutWithSideMenu>
    )
  }

  return (
    <BaseLayoutWithSideMenu>
      <div className={styles.container}>
        <div className={styles.left}>
          <ProfileData initialFields={user.proponent} />
          <NotificationsProfile />
        </div>

        <section>
          <h1>Dados cadastrais</h1>
          <ProponentDocument
            onEditDocuments={() => documentationFormToggle.open(user.proponent)}
            onReprovedDocuments={() =>
              declinedDocumentationFormToggle.open(user.proponent)
            }
            onShowDocuments={() =>
              documentationModalToggle.open(user.proponent)
            }
            proponent={user.proponent}
            onEdit={() => editProponentFormToggle.open(user.proponent)}
          />
        </section>
      </div>
      {documentationModalToggle.isOpen && (
        <DocumentationListModal
          isOpen={documentationModalToggle.isOpen}
          closeModal={documentationModalToggle.close}
          proponent={documentationModalToggle.params}
        />
      )}
    </BaseLayoutWithSideMenu>
  )
}
