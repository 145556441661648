import React from 'react'

import { FaRegTrashAlt } from 'react-icons/fa'

import { ProponentModel } from '_types'
import { Button } from 'components/button/Button'

import styles from './Proponent.module.scss'

export type ProponentProps = {
  onClick?: () => void
  onDelete?: () => void | undefined
  proponent: ProponentModel
}

export const Proponent: React.FC<ProponentProps> = ({
  onClick,
  onDelete,
  proponent
}) => {
  const { name, cpf, address, phone, email } = proponent
  const editableValues = [name, cpf, address, phone, email]
  const isFinished = Object.values(editableValues).every(p => p)

  return (
    <div className={styles.container}>
      <h1>{name}</h1>
      <Info label='CPF: -' text={cpf} />
      <Info label='Endereço: -' text={address} />
      <Info text={phone} />
      <Info text={email} />
      <div className={styles['button-wrapper']}>
        {onDelete ? (
          <button
            type='button'
            className={styles.delete}
            onClick={onDelete}
            aria-label='Excluir proponente'
          >
            <FaRegTrashAlt
              size={18}
              role='Ícone de lata de lixo indicando exclusão'
            />
          </button>
        ) : (
          <div />
        )}
        <Button
          label={isFinished ? 'Editar' : 'Finalizar cadastro'}
          onClick={onClick}
        />
      </div>
    </div>
  )
}

type InfoProps = {
  text?: string
  label?: string
}
const Info: React.FC<InfoProps> = ({ text, label }) => (
  <p className={!text ? styles.empty : ''}>{!text ? label : text}</p>
)
